import { useState } from "react";
import ProfileMenu from "../../components/ProfileMenu";

export default function Reload () {
const status = 'Bronze' // bronze silver gold diamond
const statusLvl = 1;
const reloadValue = 10000;
const percentByStatus = {'Bronze':{1:1,2:2,3:3,4:4,5:5,6:6},'Silver':{1:1,2:2,3:3,4:4,5:5,6:6},'Gold':{1:1,2:2,3:3,4:4,5:5,6:6},'Diamond':{1:1,2:2,3:3,4:4,5:5,6:6}}
    return(<>
        <div className="Profile-Block">
            <ProfileMenu url={'/profile/reload'}/>
            <div className="Profile-Block__container">
    <h1>Релоад</h1>
    <p>Релоад будет начислен на ваш основной кошелек</p>
           <div className="Profile-Block__fields">
 <nav>
    <span style={{opacity: 0.8}}>Доступный релоад</span>
    <div className="reload-block">
        <input disabled value={reloadValue.toLocaleString('en-US')} type="text" name="" id="" />
        <a href="" className="btn">Получить</a>
    </div>
    <p>Ваш статус - <span className={status.toLowerCase()}>{status +' '+ statusLvl}</span> </p>
    <p>{percentByStatus[status][statusLvl]}% от оборота </p>
 </nav>
            </div>
        </div>
        </div>
        
        </>)
}