import { useAtom } from "jotai"
import { useEffect, useRef, useState } from "react"
import {
    authAtom,
    avatarAtom,
    balanceAtom,
    idAtom,
    isTelegramAtom,
    needFinishReg,
    notificationsAtom,
    payDataAtom,
    socket,
    socketAtom,
    telegramUsernameAtom,
    usernameAtom,
} from "../App"
import axios from "axios"
import { toast } from "react-toastify"
import { useLocation, useNavigate } from "react-router-dom"
import { io } from "socket.io-client"

export default function AuthTg() {
    const [needFill, setNeedfill] = useAtom(needFinishReg)
    const [payData, setPayData] = useAtom(payDataAtom)
    const [notications, setNotications] = useAtom(notificationsAtom)
    const [isTelegram, setTelegram] = useAtom(isTelegramAtom)
    const [username, setUsername] = useAtom(usernameAtom)
    const [id, setId] = useAtom(idAtom)
    const [avatar, setAvatar] = useAtom(avatarAtom)
    const [telegramUsername, setTelegramUsername] = useAtom(telegramUsernameAtom)
    const location = useLocation()
    const navigate = useNavigate()
    const [balance, setBalance] = useAtom(balanceAtom)
    const [auth, setAuth] = useAtom(authAtom)
    const telegramWrapperRef = useRef(null)
    const [socket, setSocket] = useAtom(socketAtom)

    const loadSocket = () => {
        var d = io.connect(process.env.REACT_APP_SOCKET, {
            auth: {
                token: localStorage.getItem("accessToken"),
            },
            secure: true,
        })
        d.on("payment.crypto.success", (data) => {
            toast.success("Платеж через криптовалюту зачислен!")
        })
        d.on("payment.crypto.pending", (data) => {
            toast.info("Платеж через криптовалюту создан и находится в ожидании оплаты!")
        })
        d.on("payment.bank.success", (data) => {
            if (!payData.id == data.transaction_id) {
                return
            }

            setPayData((payData) => ({ ...payData, open: true, loading: "success" }))
            setTimeout(() => {
                setPayData((payData) => ({ open: false }))
            }, 4000)
        })
        d.on("payment.bank.cancelled", (data) => {
            if (!payData.id == data.transaction_id) {
                return
            }

            setPayData((payData) => ({ ...payData, open: true, loading: "cancel" }))
            setTimeout(() => {
                setPayData({ open: false })
            }, 4000)
        })
        d.on("balanceUpdated", (data) => {
            setBalance({ ...balance, RUB: data.balance })
        })
        setSocket(d)
    }
    const LoadAuthData = () => {
        axios
            .get(process.env.REACT_APP_API + "/user/me", {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("accessToken"),
                },
            })
            .then((response) => {
                setNotications(response.data.notications[0])
                setUsername(response.data.user.name)
                setId(response.data.user.id)
                setNeedfill(!response.data.user.isProfileFilled)
                setBalance({ RUB: response.data.user.balance, USD: 0, EUR: 0 })
                setAuth(true)
                loadSocket()
            })
            .catch((error) => {})
    }
    const [response, setResponse] = useState("resp")
    const param = new URL(window.location.href).searchParams
    let tg = window.Telegram.WebApp //получаем объект webapp телеграма
    const [LoadedAuth, setLoaded] = useState(false)
    useEffect(() => {
        var prm = new URLSearchParams(document.location.search)
        setResponse(prm.get("user_referral_id"))
        let app = window.Telegram.WebApp;
        app.ready?.()
        app.isClosingConfirmationEnabled = true
        app.allow_vertical_swipe = false
        app.disableVerticalSwipes?.()
        app.expand?.()

        if (tg.initDataUnsafe.user != null && !LoadedAuth && (!prm.get("user_referral_id") || localStorage.getItem('accessToken') != null)) {
            //if (tg.initDataUnsafe.user != null && !LoadedAuth ) {

            setTelegram(true)

            setTelegramUsername(tg.initDataUnsafe.user.first_name + " " + tg.initDataUnsafe.user.last_name)

            setLoaded(true)

            var data = {
                first_name: tg.initDataUnsafe.user.first_name,
                last_name: tg.initDataUnsafe.user.last_name,
                id: tg.initDataUnsafe.user.id,

                username: tg.initDataUnsafe.user.username,
                photo_url: tg.initDataUnsafe.user.photo_url,
                auth_date: tg.initDataUnsafe.auth_date,
                hash: tg.initDataUnsafe.hash,
            }
            if (localStorage.getItem("referralId")) {
                data.user_referral_id = localStorage.getItem("referralId")
                localStorage.removeItem("referralId")
            }
            if (localStorage.getItem("referralInvitationId")) {
                data.referral_invitation_id = localStorage.getItem("referralInvitationId")
                localStorage.removeItem("referralInvitationId")
            }
            axios
                .post(process.env.REACT_APP_API + "/auth/telegram", data)
                .then((response) => {
                    setAuth(true)

                    localStorage.setItem("accessToken", response.data.tokens.accessToken)

                    LoadAuthData()
                })
                .catch((error) => {})
        }
    }, [])

    return <></>
}
