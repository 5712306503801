import { useEffect, useState } from "react";
import ProfileMenu from "../../../components/ProfileMenu";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import axios from "axios";
export default function WalletHistory () {
const [t,i18n] = useTranslation();
    const [history,setHistory] = useState([
 ])
const getMethodLabel = (val) => {
    if (val == 'cashback')return 'Кэшбек';
    if (val =='deposit')return 'Депозит';
}
const getReqLabel = (val) => {
if (val == 'card')return 'Карта';
if (val == 'crypto')return 'Крипта';
if (val == 'sbp')return 'СБП';
if (val == 'cashback') return 'На счёт';
return val;
}
const [loaded,setLoaded] = useState(false);
useEffect(()=>{
    console.log('trying to aa');
if (!loaded){
    setLoaded(true);
    axios.get(process.env.REACT_APP_API+'/user/wallet-history',{
        headers: {
        Authorization: 'Bearer '+localStorage.getItem('accessToken')
      }
      }).then((response)=>{
     
     
        var fullArr = response.data.transactions;
        for (var i = 0; i < response.data.withdrawals.length; i++){
          //  fullArr.push(response.data.withdrawals[i]);
            fullArr.push({
                timestamp: response.data.withdrawals[i].timestamp,
                status: response.data.withdrawals[i].status,
method: 'Вывод',
req:  response.data.withdrawals[i].card,
amount:  response.data.withdrawals[i].amount,
            })
        }

        console.log(JSON.stringify(fullArr));

if (fullArr.length > 0){
    
    setHistory(fullArr.map(item=>(
        
        {date: item.timestamp, type: item.method == 'Вывод' ? item.method : getMethodLabel(item.method) , req: getReqLabel(item.method),  sum: item.amount,
             status: item.status === "success" ? 1 : item.status == 'cancelled' ? -1 : 0, currency: 'RUB'}
            
            )))
}
    }).catch((err)=>{
        console.log(err);
    });

}
},[]);
const [selectedCurrency,setSelectedCurrency] = useState('RUB');
    return(<>
        <div className="Profile-Block">
            <ProfileMenu url={'/profile/wallet/history'}/>
            <div className="Profile-Block__container">
            <div className="Mobile-Selector">
                <Link to='/profile/wallet/wallets' >{t('your_wallets')}</Link>
                <Link to='/profile/wallet/deposit' >{t('deposit')}</Link>
           
                <Link to='/profile/wallet/withdraw'>{t('withdraw')}</Link>
                <Link to='/profile/wallet/history' className="selected" >История</Link>
            </div>
                <span> <p>{t('wallet')}</p>  <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M2.5 7.08329L4.58333 4.99996L2.5 2.91663M5.41667 7.08329L7.5 4.99996L5.41667 2.91663" stroke="white" stroke-opacity="0.3" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    <p>{t("history")}</p>
    
    </span>
    <h1>{t('wallet')}</h1>
           <div className="Profile-Block__fields">
       <nav>
        <p>{t('wallet_history')}</p>
        <div className="selector">
                    <div onClick={(e)=>{e.preventDefault();e.target.parentElement.classList.toggle('open')
               
                }} className="selector__header">
                        <p className="selector__value">
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="8" cy="8" r="8" fill="#CC3332"/>
<path d="M5.97 12V4.86H7.85C8.72333 4.86 9.37 5.03667 9.79 5.39C10.2167 5.74333 10.43 6.25667 10.43 6.93C10.43 7.38333 10.3267 7.77333 10.12 8.1C9.91333 8.42 9.60667 8.66667 9.2 8.84C8.8 9.01333 8.30333 9.1 7.71 9.1H6.87V12H5.97ZM5.1 10.64V9.99H8.52V10.64H5.1ZM5.1 9.1V8.34H7.39V9.1H5.1ZM7.58 8.34C7.98667 8.34 8.33 8.29667 8.61 8.21C8.89667 8.12333 9.11667 7.98 9.27 7.78C9.42333 7.58 9.5 7.30667 9.5 6.96C9.5 6.50667 9.36 6.17 9.08 5.95C8.8 5.73 8.36333 5.62 7.77 5.62H6.87V8.34H7.58Z" fill="white"/>
</svg>

                            RUB</p>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M6 9L12 15L18 9" stroke="#7762CD" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

                    </div>
                    <div onClick={(e)=>{
                        if (e.target.classList.contains('selector__body'))return;
                        e.target.parentElement.previousSibling.querySelector('p').innerHTML = e.target.innerHTML;
                        e.target.parentElement.parentElement.classList.remove('open');
                        setSelectedCurrency(e.target.textContent);
                    }} className="selector__body">
                        <div>
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="8" cy="8" r="8" fill="#CC3332"/>
<path d="M5.97 12V4.86H7.85C8.72333 4.86 9.37 5.03667 9.79 5.39C10.2167 5.74333 10.43 6.25667 10.43 6.93C10.43 7.38333 10.3267 7.77333 10.12 8.1C9.91333 8.42 9.60667 8.66667 9.2 8.84C8.8 9.01333 8.30333 9.1 7.71 9.1H6.87V12H5.97ZM5.1 10.64V9.99H8.52V10.64H5.1ZM5.1 9.1V8.34H7.39V9.1H5.1ZM7.58 8.34C7.98667 8.34 8.33 8.29667 8.61 8.21C8.89667 8.12333 9.11667 7.98 9.27 7.78C9.42333 7.58 9.5 7.30667 9.5 6.96C9.5 6.50667 9.36 6.17 9.08 5.95C8.8 5.73 8.36333 5.62 7.77 5.62H6.87V8.34H7.58Z" fill="white"/>
</svg>
                     RUB

     
                        </div>
                        <div>
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="8" cy="8" r="8" fill="#329ECC"/>
<path d="M10.74 6.2L9.81 6.89C9.38 6.29 8.96 5.98 8.27 5.98C7.55 5.98 7.01 6.44 6.73 7.2H8.88V8.05H6.54C6.51 8.2 6.52 8.34 6.52 8.49V8.5C6.52 8.63 6.52 8.76 6.53 8.89H8.88V9.75H6.71C6.99 10.55 7.56 11.03 8.35 11.02C9.01 11.02 9.42 10.68 9.88 10.06L10.76 10.71C10.2 11.54 9.48 12.12 8.34 12.12C6.85 12.12 5.83 11.17 5.43 9.75H4.52V8.89H5.29C5.28 8.77 5.28 8.64 5.28 8.51V8.5C5.28 8.35 5.27 8.2 5.3 8.05H4.52V7.2H5.46C5.87 5.85 6.86 4.88 8.3 4.88C9.53 4.88 10.2 5.44 10.74 6.2Z" fill="white"/>
</svg>

                    EUR

     
                        </div>
                        <div>
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="8" cy="8" r="8" fill="#3532CC"/>
<path d="M10.6 5.78L10.04 6.72C9.6 6.4 9.15 6.18 8.68 6.07V7.99C10.19 8.38 10.86 8.96 10.85 10.04V10.06C10.85 11.13 9.99 11.89 8.72 12V12.98H7.85V11.98C6.97 11.87 6.17 11.54 5.49 10.99L6.13 10.07C6.7 10.54 7.24 10.84 7.89 10.95V8.98C6.43 8.61 5.75 8.06 5.75 6.93V6.92C5.75 5.84 6.6 5.11 7.85 5V4.42H8.72V5.02C9.44 5.11 10.04 5.37 10.6 5.78ZM9.67 10.13V10.11C9.67 9.67 9.45 9.4 8.68 9.17V10.99C9.32 10.92 9.67 10.61 9.67 10.13ZM6.93 6.84V6.85C6.93 7.27 7.12 7.54 7.89 7.78V6.01C7.25 6.08 6.92 6.41 6.93 6.84Z" fill="white"/>
</svg>

                    USD

     
                        </div>
                    </div>
                </div>
                
       </nav>
       <div className="Wallet-History">
       <section>
        <p>{t('date')}</p>
        <p>{t('type')}</p>
        <p>{t('credentials')}</p>
        <p>{t('amount')}</p>
        <p>{t('status')}</p>
    </section>
<div>
{history.length == 0 ? <p style={{opacity: '0.7',textAlign: 'center'}}>Операций не найдено</p> : history.sort((a,b)=>Number(new Date( b.date ))-Number(new Date( a.date ))).map(item=>
<><div>
    <nav>
    <p>{t('date')}</p>
        <p>{t('type')}</p>
        <p>{t('credentials')}</p>
        <p>{t('amount')}</p>
        <p>{t('status')}</p>
    </nav>
    <div>
    <p>{new Date(item.date).toLocaleString('en-GB') }</p>
    <p>{item.type}</p>
    <p>{item.req}</p>
    <p>{item.sum.toLocaleString('en-US')} {item.currency}</p>
    {item.status == 1 ? <p className="success">{t('success')}</p> :  item.status == 0 ? <p className="waiting">{t('waiting')}</p> :
     <p className="error">{t('cancelled')}</p>}
    </div> </div></>)}
   
</div>
       </div>
            </div>
        </div>
        </div>
        
        </>)
}