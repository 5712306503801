import { useEffect, useState } from "react";
import ProfileMenu from "../../components/ProfileMenu";
import { useTranslation } from "react-i18next";
export default function SelfLimitation () {
    const [t, i18n] = useTranslation();
    const timingsAvailable = [
        {'tag':t('t1year'),'time':31563000},
        {'tag':t('t1month'),'time':2592000},
        {'tag':t('t2week'),'time':1209600},
        {'tag':t('t1week'),'time':604800},
        {'tag':t('t3day'),'time':259200},
        {'tag':t('t1day'),'time':86400}
    
    ]
    const [selectedTimeValue,setSelectedTimeValue] = useState(timingsAvailable[0].tag);
const [limitationRemaining,setLimitationRemaining] = useState(0);
useEffect(()=>{
    if (!limitationRemaining)return;
    const intervalId =  setInterval(()=>{
       
        
        setLimitationRemaining(limitationRemaining-1);
    },1000);

    return () => clearInterval(intervalId);
},[limitationRemaining]);
const secondsToDhms = () => {
    var seconds = limitationRemaining;
    var d = Math.floor(seconds / (3600*24));
    var h = Math.floor(seconds % (3600*24) / 3600);
    var m = Math.floor(seconds % 3600 / 60);
    var s = Math.floor(seconds % 60);
    
    var dDisplay = d > 0 ? d + " d " : "";
    var hDisplay = h > 0 ? h + " h. " : "";
    var mDisplay = m > 0 ? m + " min. " : "";
    var sDisplay = s > 0 ? s + " sec. " : "";

    return dDisplay + hDisplay + mDisplay + sDisplay;
    }
return(<>
        <div className="Profile-Block">
            <ProfileMenu url={'/profile/self-limitation'}/>
            <div className="Profile-Block__container">
    <h1>{t('self_limitation')}</h1>
    <p>{t('self_limitation_desc')}</p>
           <div className="Profile-Block__fields">
{limitationRemaining == 0 ? <>
<div>
    <nav>
        <span style={{opacity:0.8}}>{t('choose_limitation_time')}</span>
        <div className="selector nomargin">
                    <div onClick={(e)=>{e.preventDefault();e.target.parentElement.classList.toggle('open')}} className="selector__header">
                        <p className="selector__value">
  

                           {selectedTimeValue}</p>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M6 9L12 15L18 9" stroke="#7762CD" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

                    </div>
                    <div onClick={(e)=>{
                        if (e.target.classList.contains('selector__body'))return;
                        setSelectedTimeValue(e.target.innerText);
                        e.target.parentElement.parentElement.classList.remove('open');
                    }} className="selector__body">
  {timingsAvailable.map(item=>
    <div><p>{item.tag}</p></div>
  )}
                    </div>
                </div>
        <a className="btn" onClick={()=>{
            var index = 0;

            for (var i = 0; i < timingsAvailable.length;i++){

                if (timingsAvailable[i]['tag'] === selectedTimeValue){

                    index = i;
break;

                }
            }
setLimitationRemaining(timingsAvailable[index]['time']);
        }}>{t('confirm')}</a>
    </nav>
</div>
</> : <>
<p>{t('self_exclusion_active')} {secondsToDhms()}</p>

</>}
            </div>
        </div>
        </div>
        
        </>)
}