import { useState } from "react"
import { useTranslation } from "react-i18next"
export default function Faq () {
    const [t,i18n] = useTranslation();
    
    const [openBlocks,setOpenBlocks] = useState([
        false,false,false,false,false
    ])
    const lng = i18n.language;

    const [faqData,setFaqData] = useState(lng==='ru' ?[
        {question: 'Как мне зарегистрировать учетную запись?',answer:'Нажмите на РЕГИСТРАЦИЯ в верхней части страницы Lotos Casino, заполните регистрационную форму и нажмите ”Создать учетную запись". На указанный вами адрес электронной почты или телефон будет отправлено приветственное письмо со ссылкой для подтверждения активации вашей учетной записи. Для получения дополнительной информации, пожалуйста, перейдите на страницу "Начало работы".'},
        {question: 'Я не получил приветственное со ссылкой для подтверждения активации учетной записи.',answer:'Некоторые поставщики услуг электронной почты могут классифицировать наши электронные письма как "Спам" и могут отправлять наши сообщения в вашу папку "Нежелательная почта". Чтобы вы и дальше получали от нас письма, добавьте наш адрес электронной почты в свой список "Безопасных" контактов.'},
        {question: 'Я забыл свой пароль и не могу войти в свой профиль.',answer:'Пожалуйста, нажмите на кнопку “Забыли пароль?” в блоке “Вход” в верхней части страницы, введите свой адрес электронной почты, и мы вышлем вам инструкции по сбросу пароля.'},
        {question: 'Каждый ли может играть в казино Lotos на реальные деньги?',answer:'Для того, чтобы играть на реальные деньги, вам должно быть не менее 18 лет. Для игроков в "Режима веселья" нет возрастных ограничений. Также в нашем казино существуют ограничения по странам. Игроки из США, Израиля и Великобритании не могут играть на реальные деньги в Lotoscas.com . В некоторых странах азартные игры онлайн запрещены законом. Вы несете ответственность за проверку и соблюдение законов, которые применяются в вашей стране (или штате) в отношении ставок, до регистрации.'},
        {question: 'Как мне внести депозит?',answer:'Существуют различные варианты внесения депозита. Вы можете узнать больше о доступных способах оплаты для внесения депозитов на странице Платежи.'},
        {question: 'Как я узнаю были ли внесенные средства зачислены на мой баланс?',answer:'Как только вы совершите перевод, деньги сразу же появятся в блоке “Баланс” разделе “Кошелька”.'},
        {question: 'Как мне снять деньги со своего счета?',answer:'Ваши деньги могут быть выведены на ваш адрес. Пожалуйста, нажмите на кнопку “Вывод средств” в “Кошельке” после входа в ваш профиль и следуйте предложенным инструкциям. Пожалуйста, обратите внимание, что вам не нужно выводить свои деньги в конце каждой игровой сессии. Он будет сохранен в вашей учетной записи и доступен во время ваших следующих игровых сессий. Вы можете прочитать больше о нашей политике вывода средств и возврата средств на странице "Правила и условия".'},
{question:'Я пытаюсь внести средства, но система этого не позволяет. В чем причина?',
answer:'Lotos Casino принимает игроков только из юрисдикций, где онлайн-игры на реальные деньги не запрещены законом. Невозможность внести деньги на ваш счет может быть вызвана тем фактом, что в юрисдикции вашей страны проживания действуют внутренние правила, запрещающие онлайн-игры.'},
{question: 'Ваши игры идут медленно и иногда обрываются. Почему так и как этого избежать?',
answer: 'Скорость игр зависит исключительно от подключения к Интернету. Поскольку наши игры требуют постоянного обмена данными, для них требуется достаточная пропускная способность Интернета. Мы рекомендуем ограничить количество других веб-приложений, запущенных на вашем компьютере одновременно с нашими играми, чтобы повысить их производительность.'},
{question: 'Могу ли я играть со своего мобильного устройства?',
answer:'Да, наши игры удобны для мобильных устройств. Просто откройте www.lotoscas.com и запустите любую игру, отображаемую на главной странице казино!'},
{question: 'У меня проблемы с воспроизведением ваших игр на мобильном устройстве.',
answer:'Проблема может возникнуть из-за того, что вы используете старую модель мобильного устройства, которую наши игры не поддерживают. Пожалуйста, свяжитесь с нашей службой поддержки по адресу support@lotoscas.com предоставьте подробную информацию о модели мобильного устройства, операционной системе и браузере, который вы используете, и мы проверим наличие возможных проблем.'},
{question: 'Как я могу быть уверен что процент выплат в ваших играх справедлив?',
answer:'Казино основано на программном обеспечении, сертифицированном Itech Labs, австралийской лабораторией тестирования систем онлайн-игр, соответствующей стандарту ISO / IEC 17025. Это означает, что ГСЧ (генератор случайных чисел) соответствует требованиям индустрии, и все результаты, которые вы получаете во время игры (барабаны в слотах и карты, раздаваемые в карточных играх), являются случайными. Выплаты по всем играм также являются стандартными для индустрии и составляют от 95 до 98%.'},
{question: 'Что произойдет если мое интернет-соединение оборвется?',
answer:'Если ваше интернет-соединение выйдет из строя, вы сможете возобновить игру с того места, где игра была остановлена, после восстановления соединения. Исключением являются многопользовательские игры, в которых другие игроки также принимают участие в ставках.'},
{question: 'Как я могу получить Приветственный бонус?',
answer:'Чтобы получить свой приветственный бонус, зарегистрируйте учетную запись в Lotoscasino и просто внесите депозит на сумму не менее 10 $/ €. Как только ваш платеж будет произведен, бонус мгновенно пополнит ваш аккаунт.'},
{question: 'Могу ли я вывести свой бонус?',
answer:'Да, вы можете вывести свой бонус, но только после выполнения требования по отыгрышу бонуса. Если вы запросите вывод средств до достижения минимальных требований по отыгрышу, бонус и выигрыши будут аннулированы.'},
{question: 'Где я могу увидеть свои бонусы?',
answer:'Как только вы вносите депозит, соответствующий условиям определенного бонуса, соответствующий бонус автоматически зачисляется на ваш баланс. Чтобы просмотреть все бонусы, пожалуйста, перейдите на вкладку “Мои бонусы” в разделе “Мой аккаунт” после входа в систему. Бонусы также отображаются в виде отдельной колонки в блоке “Баланс” раздела “Мой аккаунт”.'},
{question: 'Как Lotoscasino защищает мою конфиденциальность?',
answer:'Lotoscasino использует технологию SSL и новейшие методы борьбы с мошенничеством, чтобы гарантировать полную финансовую безопасность игроков, поэтому, расплачиваясь своей кредитной картой, вы можете быть на 100% уверены в ее безопасности. Мы также храним вашу личную информацию в тайне и используем ее только в целях предоставления вам игровых услуг. Для получения дополнительной информации, пожалуйста, ознакомьтесь с нашей Политикой конфиденциальности.'},

   ] : [
    {
        question: 'How can I register an account?', 
        answer: 'Click on REGISTER at the top of the Lotos Casino page, fill out the registration form, and click "Create Account". A welcome email with a link to confirm the activation of your account will be sent to the email address or phone number you provided. For more information, please visit the "Getting Started" page.'
    },
    {
        question: `I haven't received the welcome email with the link to confirm account activation.`, 
        answer: 'Some email service providers may classify our emails as "Spam" and might send our messages to your "Junk Mail" folder. To continue receiving emails from us, please add our email address to your list of "Safe" contacts.'
    },
    {
        question: `I forgot my password and can't log into my profile.`, 
        answer: 'Please click on the "Forgot Password?" button in the "Login" section at the top of the page, enter your email address, and we will send you instructions to reset your password.'
    },
    {
        question: 'Can everyone play at Lotos Casino for real money?', 
        answer: 'To play for real money, you must be at least 18 years old. There are no age restrictions for players in "Fun Mode". Our casino also has country restrictions. Players from the USA, Israel, and the United Kingdom cannot play for real money at Lotoscas.com. Online gambling is prohibited by law in some countries. You are responsible for checking and complying with the laws that apply in your country (or state) regarding betting, before registering.'
    },
    {
        question: 'How do I make a deposit?', 
        answer: 'There are various options for making a deposit. You can learn more about the available payment methods for deposits on the Payments page.'
    },
    {
        question: 'How will I know if the funds have been credited to my account?', 
        answer: 'Once you make a transfer, the money will immediately appear in the "Balance" section of the "Wallet".'
    },
    {
        question: 'How do I withdraw money from my account?', 
        answer: 'Your money can be withdrawn to your address. Please click on the "Withdraw Funds" button in the "Wallet" after logging into your profile and follow the instructions provided. Please note that you do not need to withdraw your money at the end of each gaming session. It will be saved in your account and available during your next gaming sessions. You can read more about our withdrawal and refund policy on the "Terms and Conditions" page.'
    },
    {
        question: `I'm trying to deposit funds, but the system won't allow it. What's the reason?`, 
        answer: 'Lotos Casino accepts players only from jurisdictions where online real-money gaming is not prohibited by law. The inability to deposit money into your account may be due to internal regulations in your country of residence that prohibit online gaming.'
    },
    {
        question: 'Your games are slow and sometimes disconnect. Why is this, and how can it be avoided?', 
        answer: 'The speed of the games depends solely on your Internet connection. Since our games require continuous data exchange, they need sufficient Internet bandwidth. We recommend limiting the number of other web applications running on your computer simultaneously with our games to improve their performance.'
    },
    {
        question: 'Can I play from my mobile device?', 
        answer: `Yes, our games are mobile-friendly. Just open www.lotoscas.com and launch any game displayed on the casino's homepage!`
    },
    {
        question: `I'm having trouble playing your games on my mobile device.`, 
        answer: 'The problem might be due to using an old model of a mobile device not supported by our games. Please contact our support service at support@lotoscas.com with detailed information about your mobile device model, operating system, and browser you are using, and we will check for any possible issues.'
    },
    {
        question: 'How can I be sure that the payout percentages in your games are fair?', 
        answer: 'The casino is based on software certified by iTech Labs, an Australian online gaming systems testing laboratory, which complies with the ISO/IEC 17025 standard. This means that the Random Number Generator (RNG) meets industry requirements, and all the results you get during the game (slot reels and cards dealt in card games) are random. Payouts for all games are also industry-standard, ranging from 95 to 98%.'
    },
    {
        question: 'What happens if my internet connection is lost?', 
        answer: 'If your internet connection fails, you will be able to resume the game from where it was stopped once the connection is restored. The exception is multiplayer games where other players are also involved in betting.'
    },
    {
        question: 'How can I receive the Welcome Bonus?', 
        answer: 'To receive your welcome bonus, register an account at Lotoscasino and simply make a deposit of at least $/€10. As soon as your payment is made, the bonus will instantly be credited to your account.'
    },
    {
        question: 'Can I withdraw my bonus?', 
        answer: 'Yes, you can withdraw your bonus, but only after fulfilling the bonus wagering requirement. If you request a withdrawal before meeting the minimum wagering requirements, the bonus and winnings will be canceled.'
    },
    {
        question: 'Where can I see my bonuses?', 
        answer: 'As soon as you make a deposit that meets the conditions of a specific bonus, the corresponding bonus is automatically credited to your balance. To view all your bonuses, please go to the "My Bonuses" tab in the "My Account" section after logging in. Bonuses are also displayed in a separate column in the "Balance" section of "My Account".'
    },
    {
        question: 'How does Lotoscasino protect my privacy?', 
        answer: 'Lotoscasino uses SSL technology and the latest anti-fraud methods to ensure the complete financial security of players. Therefore, when paying with your credit card, you can be 100% sure of its safety. We also keep your personal information confidential and use it'
    }
  
   ]
    )
    return ( <>
    <div className="text-page-container container faq-container">
        <h1>{t('faq')}</h1>
<p>{t("faq_desc_p1")}.</p>

<nav onClick={(e)=>{
if (e.target.nodeName == 'SPAN'){
    if (!e.target.parentElement.classList.contains('open')){
    e.target.parentElement.classList.toggle('open');
    setTimeout( ()=>{   e.target.parentElement.classList.toggle('ride')},10);
}else {
    e.target.parentElement.classList.toggle('ride');
    setTimeout( ()=>{   e.target.parentElement.classList.toggle('open')},110);

}
}
}}>
 {faqData.map(item=><div> <span>{item.question}</span>
 

  <p>{item.answer}</p>
 
   </div>)}
</nav>
    </div>
    </>)
}