import axios from "axios";
import { useAtom } from "jotai";
import { idAtom, usernameAtom } from "../App";

export function formatItem(item) {
  if (!item) return item;
  var str = item.charAt(0).toUpperCase() + item.slice(1);
  while (str.includes("_")) {
    str = str.replace("_", " ");
  }

  return str;
}
export function checkCaptcha() {
  const form = document.getElementById("form");

  function onloadFunction() {
    if (!window.smartCaptcha) {
      return;
    }

    window.smartCaptcha.render("captcha-container", {
      sitekey: "<ключ_клиентской_части>",
      invisible: true, // Сделать капчу невидимой
      callback: callback,
    });
  }

  function callback(token) {
    form.submit();
  }
}
