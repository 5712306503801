import ProfileMenu from "../../components/ProfileMenu";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { Link } from "react-router-dom";
export default function Verification() {
    const { t, i18n } = useTranslation();
    const [step1ImagePath,setStep1ImagePath] = useState(null);
    const [step2ImagePath,setStep2ImagePath] = useState(null);
    
    const [verificationPopupStatus,setVerificationPopupStatus] = useState(0);
    const emailVerifyStatus = 2;
    const phoneVerifyStatus= 2;
    const documentsVerifyStatus = 0;
    const addressVerifyStatus = 2;
    const paymentVerifyStatus= 3;
    const userEmail = '';
    const userPhone = ''
function onDragStart(e){
    e.preventDefault();
    
}

    return(<>
    <div className="Profile-Block">
        <ProfileMenu url={'/profile/verification'}/>
        <div className="Profile-Block__container">
        <div className="Mobile-Selector">
                <Link to='/profile/main' >{t('main')}</Link>
                <Link to='/profile/verification' className="selected">{t('verification')}</Link>
           
                <Link to='/profile/safety'>{t('safety')}</Link>
           
            </div>
            <span> <p>{t('profile')}</p>  <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M2.5 7.08329L4.58333 4.99996L2.5 2.91663M5.41667 7.08329L7.5 4.99996L5.41667 2.91663" stroke="white" stroke-opacity="0.3" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
<p>{t('verification')}</p>

</span>
<h1>{t('profile')}</h1>
        <div className="Profile-Block__fields">
            <div>
                <nav>
                    <p>{t('email_verification')}</p>
                   <a className={emailVerifyStatus > 0 ? 'disabled btn' : 'btn'}>
                        {emailVerifyStatus == 0 ? t('send_code') : emailVerifyStatus == 1 ? t('waiting_confirmation') : t('completed') }
                    </a>
                </nav>
                <nav>
                    <p>{t('phone_verification')}</p>
                    <a className={phoneVerifyStatus > 0 ? 'disabled btn' : 'btn'}>
                        {phoneVerifyStatus == 0 ? t('send_code') : phoneVerifyStatus == 1 ? t('waiting_confirmation') : t('completed') }
                    </a>
                </nav>
            </div>
        { false && <>    <nav>
                    <p>{t('documents_verification')}</p>
                <span>{t('documents_verification_desc')}</span>
                   <a onClick={()=>{
                    if (documentsVerifyStatus == 0)setVerificationPopupStatus(1);
                    
                   }} className={documentsVerifyStatus > 0 ? 'disabled btn' : 'btn'}>
                        {documentsVerifyStatus == 0 ? t('pass_verification') : documentsVerifyStatus == 1 ? t('waiting_confirmation') : t('completed') }
                    </a>
                </nav>
                <nav>
                    <p>{t('address_verification')}</p>
                 <span>{t('address_verification_desc')}</span>
                   <a className={addressVerifyStatus > 0 ? 'disabled btn' : 'btn'}>
                        {addressVerifyStatus == 0 ? t('pass_verification') : addressVerifyStatus == 1 ? t('waiting_confirmation') : t('completed') }
                    </a>
                </nav>
                <nav>
                    <p>{t('payment_verification')}</p>
                    <span>{t('payment_verification_desc')}</span>
                   <a className={paymentVerifyStatus > 0 ? 'disabled btn' : 'btn'}>
                        {paymentVerifyStatus == 0 ? t('pass_verification') : paymentVerifyStatus == 1 ? t('waiting_confirmation') : paymentVerifyStatus == 3 ? t('not_requested') : t('completed') }
                    </a>
                </nav></> }
        </div>
    </div>
    </div>
    <div style={{display:verificationPopupStatus>0 ? 'flex' : 'none'}} className="Profile-Block__Verification-Popup__container" onClick={(e)=>{
        if (e.target.classList.contains('Profile-Block__Verification-Popup__container'))
        setVerificationPopupStatus(0);
    }}>
    <div className="Profile-Block__Verification-Popup">
        { verificationPopupStatus == 1 ? 
        <>
        <svg onClick={()=>{setVerificationPopupStatus(0)}} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12 4L4 12M4 4L12 12" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

<h2>{t('documents_verification')}</h2>
<p>{t('documents_verification_step1')} (<a onClick={()=>{
    setVerificationPopupStatus(2);
}}>{t('requirements')}</a>)</p>
<section onDragLeave={e=>onDragStart(e)} onDragStart={e=>onDragStart(e)} onDragOver={e=>onDragStart(e)} on onDrop={(e)=>{
    e.preventDefault();
    var name = e.dataTransfer.files[0].name
    if (!name.endsWith('.png') && !name.endsWith('.jpeg') && !name.endsWith('.jpg'))return


    setStep1ImagePath(name);
}}>
    <label>
        <input accept="image/png,image/jpeg" onChange={(e)=>{
            if (e.target.files.length === 0)return;
          setStep1ImagePath(e.target.files[0].name); 
        }} type="file" />
        {step1ImagePath == null ?
    <p>
  <a>  {t('choose')} </a> {t('or_drag_image')}
    </p>
    : <p>{step1ImagePath}</p>  }
    </label>
</section>
<a className="btn" onClick={()=>{
setVerificationPopupStatus(3);
}}>{t('next')}</a>
</>
: verificationPopupStatus == 2 ?
<>
<svg onClick={()=>{setVerificationPopupStatus(1)}} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12 4L4 12M4 4L12 12" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

<h2>{t('documents_verification_requirements')}</h2>
<span>
{t('documents_verification_step1_requirements')}</span>
</>

: verificationPopupStatus == 3 ? 
<>
<svg onClick={()=>{setVerificationPopupStatus(0)}} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12 4L4 12M4 4L12 12" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

<h2>{t('documents_verification')}</h2>
<p>{t('documents_verification_step2')} (<a onClick={()=>{
    setVerificationPopupStatus(4);
}}>{t('requirements')}</a>)</p>
<section  onDragLeave={e=>onDragStart(e)} onDragStart={e=>onDragStart(e)} onDragOver={e=>onDragStart(e)} on onDrop={(e)=>{
    e.preventDefault();
    var name = e.dataTransfer.files[0].name
    if (!name.endsWith('.png') && !name.endsWith('.jpeg') && !name.endsWith('.jpg'))return
    setStep2ImagePath(name);
}}>
    <label>
        <input onChange={(e)=>{
            if (e.target.files.length === 0)return;
            var name = e.target.files[0].name.toLowerCase();

          setStep2ImagePath(name); 
        }} type="file" />
        {step2ImagePath == null ?
    <p>
  <a>  {t('choose')} </a> {t('or_drag_image')}
    </p>
    : <p>{step2ImagePath}</p>  }
    </label>
</section>
<a className="btn" onClick={()=>{
    // send verification
setVerificationPopupStatus(0);
}}>{t('send')}</a>  
</>

: verificationPopupStatus == 4 ? 
<>
<svg onClick={()=>{setVerificationPopupStatus(3)}} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12 4L4 12M4 4L12 12" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

<h2>{t('documents_verification_requirements')}</h2>
<span>
{t('documents_verification_step2_requirements')}</span>
</> : <></>}
    </div>
    </div>
    </>)
}