import { useAtom } from "jotai";
import { Children, PropsWithChildren, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { authAtom } from "../App";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

type ProtectedRouteProps = PropsWithChildren;

export default function ProtectedRoute ({ children }:ProtectedRouteProps) {
const [t,i18n] = useTranslation();
    const navigate = useNavigate();
const [auth,setAuth] = useAtom(authAtom);
    useEffect(()=>{
if (!auth){
    toast.error(t('need_login'));
        navigate('/');
      
}
    },[navigate,auth]);
    return children  ;
}