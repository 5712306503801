import logo from "./logo.svg";

import {
  Routes,
  Route,
  Link,
  useParams,
  useSearchParams,
  useNavigate,
  useLocation,
} from "react-router-dom";
import Main from "./pages/Main";
import usePopstate from "react-usepopstate";
import Profile from "./pages/Profile/Profile";
import "./styles/globals.css";
import Header from "./components/Header";
import Verification from "./pages/Profile/Verification";
import Footer from "./components/Footer";
import ProtectedRoute from "./components/ProtectedRoute.tsx";
import { useTranslation } from "react-i18next";
import { useCallback, useState } from "react";
import { useEffect } from "react";
import { toast } from "react-toastify";
import About from "./pages/About";
import NotFound from "./pages/NotFound";
import { useLayoutEffect } from "react";
import Safety from "./pages/Profile/Safety";
import Wallets from "./pages/Profile/Wallet/Wallets";
import Deposit from "./pages/Profile/Wallet/Deposit";
import Withdraw from "./pages/Profile/Wallet/Withdraw";
import WalletHistory from "./pages/Profile/Wallet/WalletHistory";

import Notifications from "./pages/Profile/Notifications";
import Reload from "./pages/Profile/Reload";
import SelfLimitation from "./pages/Profile/SelfLimitation";
import History from "./pages/Profile/History";
import Bonuses from "./pages/Profile/Bonuses";
import BonusesPage from "./pages/Bonuses";
import Vip from "./pages/Profile/Vip";
import Faq from "./pages/Faq";
import VipClub from "./pages/VipClub";
import Payments from "./pages/Payments";
import Terms from "./pages/Terms";
import ResponsibleGambling from "./pages/Responsible-gambling";
import Policy from "./pages/Policy";
import MobileMenu from "./components/MobileMenu";
import { ToastContainer } from "react-toastify";
import Game from "./components/Game";
import axios from "axios";
import { isMobile } from "react-device-detect";
import { LoadAuthData } from "./api/Helper.js";

import { atom, useAtom } from "jotai";
import { io } from "socket.io-client";
import Referral from "./pages/Profile/Referral.jsx";
import AuthTg from "./components/AuthTg.jsx";
import Wheel from "./pages/Wheel.jsx";
import GamesPage from "./pages/GamesPage.jsx";
import { useVisitorData } from "@fingerprintjs/fingerprintjs-pro-react";
import FingerprintJS from "@fingerprintjs/fingerprintjs";
export const usernameAtom = atom("");
export const authAtom = atom(false);
export const telegramUsernameAtom = atom("");
export const pgSoftAtom = atom([]);
export const phoneAtom = atom("");
export const idAtom = atom("");
export const gamesAtom = atom([]);
export const gamesOverallAtom = atom([]);
export const isTelegramAtom = atom(false);
export const needFinishReg = atom(false);
var socket = null;

export const avatarAtom = atom(
  "/images/vip/ri_13_" + ("00".substring(0, 2 - (1 + "").length) + 1) + ".png"
);
export const notificationsAtom = atom([
  {
    date: "---",
    message: "Загрузка...",
    isViewed: false,
  },
]);
export const providerListAtom = atom([]);
export const payDataAtom = atom({
  open: false,
  isSbp: false,
});
export const payDataTimeAtom = atom(0);
export const logInOpenAtom = atom(false);
export const actualGameLinkAtom = atom("");
export const authLoadedAtom = atom(false);
export const balanceAtom = atom({
  RUB: 0,
  USD: 0,
  EUR: 0,
});
export const depositOpenAtom = atom(false);
export const socketAtom = atom("");
const fpPromise = FingerprintJS.load();
function App() {
  const [telegram, setTelegram] = useAtom(isTelegramAtom);
  const [notifications, setNotifications] = useAtom(notificationsAtom);
  const location = useLocation();
  const [needFill, setNeedFill] = useAtom(needFinishReg);
  const [loginOpen, setLoginOpen] = useAtom(logInOpenAtom);
  const params = useParams();
  const [socket, setSocket] = useAtom(socketAtom);
  const [username, setUsername] = useAtom(usernameAtom);
  const [payData, setPayData] = useAtom(payDataAtom);
  const navigate = useNavigate();
  const [auth, setAuth] = useAtom(authAtom);
  const [authLoaded, setAuthLoaded] = useAtom(authLoadedAtom);
  const [phone, setPhone] = useAtom(phoneAtom);
  const [actualGameLink, setActualGameLink] = useAtom(actualGameLinkAtom);
  const [loading, setLoading] = useState(false);
  const [id, setId] = useAtom(idAtom);
  const [games, setGames] = useAtom(gamesAtom);
  const [gamesOverall, setGamesOverall] = useAtom(gamesOverallAtom);
  const [balance, setBalance] = useAtom(balanceAtom);
  const [providerList, setProviderList] = useAtom(providerListAtom);
  const [pgSoftIds, setPgSoftIds] = useAtom(pgSoftAtom);

  const loadSocket = () => {
    var d = io.connect(process.env.REACT_APP_SOCKET, {
      auth: {
        token: localStorage.getItem("accessToken"),
      },
      secure: true,
    });

    d.on("payment.crypto.success", (data) => {
      toast.success("Платеж через криптовалюту зачислен!");
    });
    d.on("payment.crypto.pending", (data) => {
      toast.info(
        "Платеж через криптовалюту создан и находится в ожидании оплаты!"
      );
    });
    d.on("payment.bank.success", (data) => {
      if (!payData.id == data.transaction_id) {
        return;
      }
      setPayData((payData) => ({ ...payData, open: true, loading: "success" }));
      setTimeout(() => {
        setPayData((payData) => ({ open: false }));
      }, 4000);
    });
    d.on("payment.bank.cancelled", (data) => {
      if (!payData.id == data.transaction_id) {
        return;
      }

      setPayData((payData) => ({ ...payData, open: true, loading: "cancel" }));
      setTimeout(() => {
        setPayData({ open: false });
      }, 4000);
    });
    d.on("balanceUpdated", (data) => {
      setBalance({ ...balance, RUB: data.balance });
    });
    setSocket(d);
  };

  useEffect(() => {
    sendUserMetricRequest();
  }, []);

  async function sendUserMetricRequest() {
    try {
      const visitorId = await getVisitorId();

      await axios.post(
        process.env.REACT_APP_API + "/user-metrics",
        {
          type: "visit",
          visitor_id: visitorId,
        },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("accessToken"),
          },
        }
      );
    } catch (err) {
      console.error("Error sending user-metric request!");
      console.log(err.message);
    }
  }

  async function getVisitorId() {
    const fp = await fpPromise;
    const result = await fp.get();
    return result.visitorId;
  }

  useEffect(() => {
    setTimeout(function () {
      window.scrollTo(0, 1);
    }, 0);
    if (localStorage.getItem("accessToken") != null) {
      axios
        .get(process.env.REACT_APP_API + "/user/me", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("accessToken"),
          },
        })
        .then((response) => {
          console.log(
            "TELEGRAM:" + window.Telegram.WebApp.initDataUnsafe.user != null
          );
          loadSocket();
          setNeedFill(!response.data.user.isProfileFilled);
          setNotifications(response.data.notifications[0]);
          setId(response.data.user.id);
          setBalance({ ...balance, RUB: response.data.user.balance });
          setAuth(true);
          socket.emit("join", response.data.user.id);
          setAuthLoaded(true);
        })
        .catch((error) => {
          setAuthLoaded(true);
        });
    } else {
      setAuthLoaded(true);
    }

    axios
      .get(process.env.REACT_APP_API + "/games/providers")
      .then((response) => {
        setProviderList(response.data);
      });

    axios.get(process.env.REACT_APP_API + "/games/main").then((response) => {
      var gamesRef = [];
      var pgSoftList = [];
      for (var i = 0; i < response.data.length; i++) {
        if (
          (response.data[i].device == "0" && !isMobile) ||
          (response.data[i].device == "1" && isMobile) ||
          response.data[i].device == "2"
        ) {
          if (response.data[i].label == "pgsoft")
            pgSoftList.push(Number(response.data[i].id));
          gamesRef.push({
            name: response.data[i].name,
            img: response.data[i].img,
            label: response.data[i].label,
            id: response.data[i].id,
          });
        }
      }
      setPgSoftIds(pgSoftList);

      setGamesOverall(gamesRef);
      setGames(gamesRef);
    });
    window.onmessage = function (event) {
      if (
        event.data == "closeGame" ||
        event.data == "close" ||
        event.data == "notifyCloseContainer" ||
        (event.data.indexOf && event.data.indexOf("GAME_MODE:LOBBY") >= 0)
      ) {
        navigate("/");
      } else if (event.data == "closeWheel") {
        navigate("/");
      }
      if (event.data.closeGame !== undefined) {
        navigate("/");
      }
    };

    //setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 3000);
    if (isMobile) {
      var inter = setInterval(() => {
        for (var i = 0; i < document.getElementsByTagName("jdiv").length; i++) {
          var element = document.getElementsByTagName("jdiv")[i];

          if (element.className.startsWith("wrap_")) {
            element.style.bottom = "50px";

            clearInterval(inter);
          }
        }
      }, 300);
    }
  }, []);

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });
  const { t, i18n } = useTranslation();
  const handlePopState = useCallback((event) => {
    if (document.body.classList.contains("game-open"))
      window.location.href = "/";
  }, []);
  useEffect(() => {
    window.addEventListener("popstate", handlePopState);
    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, [handlePopState]);

  const { isLoading, error, data, getData } = useVisitorData(
    { extendedResult: true },
    { immediate: true }
  );

  console.log(data);
  useEffect(() => {
    if (data?.visitorId != null) {
      axios
        .post(process.env.REACT_APP_API + "/user-metrics", {
          type: "visit",
          visitor_id: data.visitorId,
        })
        .catch((err) => {});
    }
  }, [data]);

  useEffect(() => {
    setTelegram(window.Telegram.WebApp.initDataUnsafe.user != null);
    console.log(window.Telegram.WebApp.initDataUnsafe.user);
    document.body.addEventListener("click", function (e) {
      var newCu = e.target;
      while (newCu.parentElement) {
        newCu = newCu.parentElement;
        if (newCu == document.body) break;
        if (newCu.classList.contains("selector")) return;
      }
      for (var i = 0; i < document.querySelectorAll(".selector").length; i++) {
        document.querySelectorAll(".selector")[i].classList.remove("open");
      }
    });
    window.addEventListener("popstate", function (e) {
      if (actualGameLink) {
        e.preventDefault();
        setActualGameLink(false);
      }
    });
    document.onkeyup = function (e) {
      if (e.key === "Escape") setActualGameLink(false);
    };
    var prm = new URLSearchParams(document.location.search);
    if (prm.get("user_referral_id")) {
      var refId = prm.get("user_referral_id");

      localStorage.setItem("referralId", refId);

      if (!auth && localStorage.getItem('accessToken') == null) {
        navigate("/spin?user_referral_id=" + refId);
      }
    }
    if (prm.get("referral_invitation_id")) {
      var refInviteId = prm.get("referral_invitation_id");

      localStorage.setItem("referralInvitationId", refInviteId);
      if (!auth && localStorage.getItem('accessToken') == null) {
        navigate("/spin");
      }
    }

    const lng = navigator.language;

    if (localStorage.getItem("i18nextLng") != null)
      i18n.changeLanguage(localStorage.getItem("i18nextLng"));
  }, []);

  return (
    <>
      <ToastContainer theme="dark" />
      <div className="container-main">
        {location.pathname.includes("/spin") ? (
          <Wheel />
        ) : !loading ? (
          authLoaded && (
            <>
              {!location.pathname.includes("/game/") && <Header />}
              <AuthTg />

              <Routes>
                <Route path="*" element={<NotFound />}></Route>
                <Route path="/" element={<Main />}></Route>
                <Route path="/games" element={<GamesPage />}></Route>

                <Route path="/about" element={<About />}></Route>
                <Route
                  path="/profile/self-limitation"
                  element={
                    <ProtectedRoute>
                      {" "}
                      <SelfLimitation />{" "}
                    </ProtectedRoute>
                  }
                ></Route>
                <Route
                  path="/profile/reload"
                  element={
                    <ProtectedRoute>
                      {" "}
                      <Reload />{" "}
                    </ProtectedRoute>
                  }
                ></Route>
                <Route
                  path="/profile/referral"
                  element={
                    <ProtectedRoute>
                      {" "}
                      <Referral />{" "}
                    </ProtectedRoute>
                  }
                ></Route>
                <Route
                  path="/profile/bonuses"
                  element={
                    <ProtectedRoute>
                      {" "}
                      <Bonuses />{" "}
                    </ProtectedRoute>
                  }
                ></Route>
                <Route
                  path="/profile/vip"
                  element={
                    <ProtectedRoute>
                      {" "}
                      <Vip />{" "}
                    </ProtectedRoute>
                  }
                ></Route>
                <Route path="/faq" element={<Faq />}></Route>
                <Route path="/bonuses" element={<BonusesPage />}></Route>
                <Route path="/payments" element={<Payments />}></Route>
                <Route path="/terms" element={<Terms />}></Route>
                <Route path="/spin" element={<Wheel />}></Route>
                <Route path="/game/:id" element={<Game />}></Route>
                <Route
                  path="/responsible-gambling"
                  element={<ResponsibleGambling />}
                ></Route>
                <Route path="/policy" element={<Policy />}></Route>
                <Route path="/vip-club" element={<VipClub />}></Route>
                <Route
                  path="/profile/history"
                  element={
                    <ProtectedRoute>
                      {" "}
                      <History />{" "}
                    </ProtectedRoute>
                  }
                ></Route>
                <Route
                  path="/profile/notifications"
                  element={
                    <ProtectedRoute>
                      <Notifications />
                    </ProtectedRoute>
                  }
                ></Route>
                <Route
                  path="/profile/wallet/wallets"
                  element={
                    <ProtectedRoute>
                      <Wallets />
                    </ProtectedRoute>
                  }
                ></Route>
                <Route
                  path="/profile/wallet/history"
                  element={
                    <ProtectedRoute>
                      <WalletHistory />{" "}
                    </ProtectedRoute>
                  }
                ></Route>
                <Route
                  path="/profile/wallet/withdraw"
                  element={
                    <ProtectedRoute>
                      {" "}
                      <Withdraw />{" "}
                    </ProtectedRoute>
                  }
                ></Route>

                <Route
                  path="/profile/wallet/deposit"
                  element={
                    <ProtectedRoute>
                      {" "}
                      <Deposit />{" "}
                    </ProtectedRoute>
                  }
                ></Route>
                <Route
                  path="/profile/safety"
                  element={
                    <ProtectedRoute>
                      {" "}
                      <Safety />{" "}
                    </ProtectedRoute>
                  }
                ></Route>
                <Route
                  path="/profile/verification"
                  element={
                    <ProtectedRoute>
                      {" "}
                      <Verification />{" "}
                    </ProtectedRoute>
                  }
                ></Route>
                <Route
                  path="/profile/main"
                  element={
                    <ProtectedRoute>
                      {" "}
                      <Profile />{" "}
                    </ProtectedRoute>
                  }
                ></Route>
              </Routes>
              <MobileMenu />
              <Footer />
            </>
          )
        ) : (
          <>
            <div className="animation-Loading">
              <img src="/images/lotos.gif" style={{ width: "100%" }} alt="" />
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default App;
