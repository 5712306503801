import { useState } from "react";
import ProfileMenu from "../../../components/ProfileMenu";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { useAtom } from "jotai";
import {
  authAtom,
  balanceAtom,
  payDataAtom,
  payDataTimeAtom,
} from "../../../App";
import { toast } from "react-toastify";

export default function Deposit() {
  const [t, i18n] = useTranslation();
  const [balance, setBalance] = useAtom(balanceAtom);
  const [depositSum, setDepositSum] = useState('');
  const [senderName, setSenderName] = useState("");
  const [payDataTime, setPayDataTime] = useAtom(payDataTimeAtom);
  const [payData, setPayData] = useAtom(payDataAtom);
  const [depMethod, setDepMethod] = useState("card");
  
  const [auth] = useAtom(authAtom);
  const [bonuses, setBonuses] = useState([
    {
      name: "100% на депозит от 5000 RUB",
    },
    {
      name: "100% на депозит от 50000 RUB",
    },
  ]);
  const [selectedBonus, setSelectedBonus] = useState(0);
  const [selectedCurrency, setSelectedCurrency] = useState("RUB");
  return (
    <>
      <div className="Profile-Block">
        <ProfileMenu url={"/profile/wallet/deposit"} />
        <div className="Profile-Block__container">
          <div className="Mobile-Selector">
            <Link to="/profile/wallet/wallets">{t("your_wallets")}</Link>
            <Link to="/profile/wallet/deposit" className="selected">
              {t("deposit")}
            </Link>

            <Link to="/profile/wallet/withdraw">{t("withdraw")}</Link>
            <Link to="/profile/wallet/history">История</Link>
          </div>
          <span>
            {" "}
            <p>{t("wallet")}</p>{" "}
            <svg
              width="10"
              height="10"
              viewBox="0 0 10 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M2.5 7.08329L4.58333 4.99996L2.5 2.91663M5.41667 7.08329L7.5 4.99996L5.41667 2.91663"
                stroke="white"
                stroke-opacity="0.3"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <p>{t("deposit")}</p>
          </span>
          <h1>{t("wallet")}</h1>
          <div className="Profile-Block__fields">
            <div>
              <nav>
                <p>{t("refill_wallet")}</p>
                <div className="selector white-selector  ">
                  <div
                    onClick={(e) => {
                      e.preventDefault();
                      e.target.parentElement.classList.toggle("open");
                    }}
                    className="selector__header"
                  >
                    <p className="selector__value">
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle cx="8" cy="8" r="8" fill="#CC3332" />
                        <path
                          d="M5.97 12V4.86H7.85C8.72333 4.86 9.37 5.03667 9.79 5.39C10.2167 5.74333 10.43 6.25667 10.43 6.93C10.43 7.38333 10.3267 7.77333 10.12 8.1C9.91333 8.42 9.60667 8.66667 9.2 8.84C8.8 9.01333 8.30333 9.1 7.71 9.1H6.87V12H5.97ZM5.1 10.64V9.99H8.52V10.64H5.1ZM5.1 9.1V8.34H7.39V9.1H5.1ZM7.58 8.34C7.98667 8.34 8.33 8.29667 8.61 8.21C8.89667 8.12333 9.11667 7.98 9.27 7.78C9.42333 7.58 9.5 7.30667 9.5 6.96C9.5 6.50667 9.36 6.17 9.08 5.95C8.8 5.73 8.36333 5.62 7.77 5.62H6.87V8.34H7.58Z"
                          fill="white"
                        />
                      </svg>

                      {balance.RUB.toFixed(2).toLocaleString("en-US")}
                    </p>
                    <svg
                      width="17"
                      height="17"
                      viewBox="0 0 17 17"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M4.25 6.375L8.5 10.625L12.75 6.375"
                        stroke="white"
                        strokeWidth="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>

                  <div
                    onClick={(e) => {
                      if (e.target.nodeName == "A") {
                        e.target.parentElement.parentElement.parentElement.classList.remove(
                          "open"
                        );
                        return;
                      }
                      if (e.target.classList.contains("selector__body")) return;
                      e.target.parentElement.parentElement.classList.remove(
                        "open"
                      );

                      if (!e.target.nextSibling) return;
                      e.target.parentElement.previousSibling.querySelector(
                        "p"
                      ).innerHTML = e.target.innerHTML;
                    }}
                    className="selector__body"
                  >
                    <div>
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle cx="8" cy="8" r="8" fill="#CC3332" />
                        <path
                          d="M5.97 12V4.86H7.85C8.72333 4.86 9.37 5.03667 9.79 5.39C10.2167 5.74333 10.43 6.25667 10.43 6.93C10.43 7.38333 10.3267 7.77333 10.12 8.1C9.91333 8.42 9.60667 8.66667 9.2 8.84C8.8 9.01333 8.30333 9.1 7.71 9.1H6.87V12H5.97ZM5.1 10.64V9.99H8.52V10.64H5.1ZM5.1 9.1V8.34H7.39V9.1H5.1ZM7.58 8.34C7.98667 8.34 8.33 8.29667 8.61 8.21C8.89667 8.12333 9.11667 7.98 9.27 7.78C9.42333 7.58 9.5 7.30667 9.5 6.96C9.5 6.50667 9.36 6.17 9.08 5.95C8.8 5.73 8.36333 5.62 7.77 5.62H6.87V8.34H7.58Z"
                          fill="white"
                        />
                      </svg>
                      {balance.RUB.toFixed(2).toLocaleString("en-US")}
                    </div>
                  </div>
                </div>
                <p>{t("deposit_method")}</p>
                <div className="left-right-select y">
                  <div
                    onClick={(e) => {
                      e.target.parentElement
                        .querySelector(".selected")
                        .classList.remove("selected");
                      e.target.classList.add("selected");
                      setDepMethod("card");
                    }}
                    className="selected"
                  >
                    <div>
                      <img src="/images/sber.png" alt="" />
                      <svg
                        id="logosandtypes_com"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 150 150"
                      >
                        <style
                          dangerouslySetInnerHTML={{
                            __html: ".st1{fill:#ee3424}",
                          }}
                        />
                        <g id="Layer_2">
                          <path
                            id="Layer_3"
                            d="M0 0h150v150H0V0z"
                            fill="none"
                          />
                        </g>
                        <g id="alfa_bank">
                          <path
                            className="st1"
                            d="M35.2 120.7c-.7 0-1.3.6-1.3 1.3v14.6c0 .7.6 1.3 1.3 1.3h80c.7 0 1.3-.6 1.3-1.3V122c0-.7-.6-1.3-1.3-1.3h-80M113.5 88.6C112.8 86.7 90 24.4 89 21.7c-2.2-6.2-5-12.5-13.8-12.5s-11.6 6.4-13.8 12.5C60.3 24.9 37.9 86.2 37 88.6c-.2.7.1 1.4.7 1.7L51 95.4c.7.2 1.4-.1 1.6-.8.2-.6 5.2-14.2 5.7-15.7H92c.6 1.5 5.5 15.1 5.7 15.7.2.7 1 1 1.6.8.7-.2 12.5-4.8 13.3-5.1.8-.3 1.1-1.1.9-1.7zM63.8 63.8l11.4-34.2h.1l11.4 34.2H63.8z"
                          />
                        </g>
                      </svg>
                      <img src="/images/tbank.png" alt="" />
                    </div>
                    <p>Карта</p>
                  </div>
                  <div
                    onClick={(e) => {
                      e.target.parentElement
                        .querySelector(".selected")
                        .classList.remove("selected");
                      e.target.classList.add("selected");
                      setDepMethod("sbp");
                    }}
                  >
                    <div>
                      <svg
                        width="22"
                        height="22"
                        viewBox="0 0 40 40"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M4 8.70703L8.844 17.3654V22.6467L4.00567 31.288L4 8.70703Z"
                          fill="#5B57A2"
                        />
                        <path
                          d="M22.5991 14.2145L27.1381 11.4325L36.4274 11.4238L22.5991 19.8952V14.2145Z"
                          fill="#D90751"
                        />
                        <path
                          d="M22.5733 8.65566L22.599 20.119L17.7437 17.1356V0L22.5736 8.65566H22.5733Z"
                          fill="#FAB718"
                        />
                        <path
                          d="M36.4273 11.4237L27.1376 11.4323L22.5733 8.65566L17.7437 0L36.427 11.4237H36.4273Z"
                          fill="#ED6F26"
                        />
                        <path
                          d="M22.599 31.336V25.7743L17.7437 22.8477L17.7463 40.0003L22.599 31.336Z"
                          fill="#63B22F"
                        />
                        <path
                          d="M27.1266 28.5793L8.84366 17.3654L4 8.70703L36.4076 28.568L27.1263 28.5793H27.1266Z"
                          fill="#1487C9"
                        />
                        <path
                          d="M17.7466 39.9997L22.5986 31.3354L27.1262 28.5787L36.4072 28.5674L17.7466 39.9997Z"
                          fill="#017F36"
                        />
                        <path
                          d="M4.00586 31.2878L17.7835 22.8479L13.1515 20.0059L8.84419 22.6465L4.00586 31.2878Z"
                          fill="#984995"
                        />
                      </svg>
                    </div>
                    <p>СБП</p>
                  </div>
                 
                </div>
                {depMethod != "crypto" && (
                  <>
                    {" "}
                    <p>Имя отправителя</p>
                    <input
                      value={senderName}
                      onChange={(e) => {
                        setSenderName(e.target.value);
                      }}
                      type="text"
                      placeholder="Имя отправителя"
                    />
                  </>
                )}
                <p>
                  {t("amount")}, {selectedCurrency}
                </p>
                <input
                  value={depositSum}
                  onChange={(e) => {
                    setDepositSum(e.target.value);
                  }}
                  type="number"
                  placeholder="Сумма"
                />

                {false && (
                  <>
                    {" "}
                    <div
                      onClick={(e) => {
                        if (e.target.classList.contains("deposit-bonus-select"))
                          return;
                        e.target.parentElement
                          .querySelector(".selected")
                          .classList.remove("selected");
                        e.target.classList.add("selected");

                        var index = 1;
                        var children = e.target.parentNode.childNodes,
                          i = 0;
                        for (; i < children.length; i++) {
                          if (children[i] == e.target) {
                            index = i;
                          }
                        }

                        setSelectedBonus(index);
                      }}
                      className="deposit-bonus-select"
                    >
                      <div className="selected">
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M12 6V22M12 6H8.46429C7.94332 6 7.4437 5.78929 7.07533 5.41421C6.70695 5.03914 6.5 4.53043 6.5 4C6.5 3.46957 6.70695 2.96086 7.07533 2.58579C7.4437 2.21071 7.94332 2 8.46429 2C11.2143 2 12 6 12 6ZM12 6H15.5357C16.0567 6 16.5563 5.78929 16.9247 5.41421C17.293 5.03914 17.5 4.53043 17.5 4C17.5 3.46957 17.293 2.96086 16.9247 2.58579C16.5563 2.21071 16.0567 2 15.5357 2C12.7857 2 12 6 12 6ZM20 11V18.8C20 19.9201 20 20.4802 19.782 20.908C19.5903 21.2843 19.2843 21.5903 18.908 21.782C18.4802 22 17.9201 22 16.8 22L7.2 22C6.07989 22 5.51984 22 5.09202 21.782C4.71569 21.5903 4.40973 21.2843 4.21799 20.908C4 20.4802 4 19.9201 4 18.8V11M2 7.6L2 9.4C2 9.96005 2 10.2401 2.10899 10.454C2.20487 10.6422 2.35785 10.7951 2.54601 10.891C2.75992 11 3.03995 11 3.6 11L20.4 11C20.9601 11 21.2401 11 21.454 10.891C21.6422 10.7951 21.7951 10.6422 21.891 10.454C22 10.2401 22 9.96005 22 9.4V7.6C22 7.03995 22 6.75992 21.891 6.54601C21.7951 6.35785 21.6422 6.20487 21.454 6.10899C21.2401 6 20.9601 6 20.4 6L3.6 6C3.03995 6 2.75992 6 2.54601 6.10899C2.35785 6.20487 2.20487 6.35785 2.10899 6.54601C2 6.75992 2 7.03995 2 7.6Z"
                            stroke="#4D35AF"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </div>
                      <div>
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M12 6V22M12 6H8.46429C7.94332 6 7.4437 5.78929 7.07533 5.41421C6.70695 5.03914 6.5 4.53043 6.5 4C6.5 3.46957 6.70695 2.96086 7.07533 2.58579C7.4437 2.21071 7.94332 2 8.46429 2C11.2143 2 12 6 12 6ZM12 6H15.5357C16.0567 6 16.5563 5.78929 16.9247 5.41421C17.293 5.03914 17.5 4.53043 17.5 4C17.5 3.46957 17.293 2.96086 16.9247 2.58579C16.5563 2.21071 16.0567 2 15.5357 2C12.7857 2 12 6 12 6ZM20 11V18.8C20 19.9201 20 20.4802 19.782 20.908C19.5903 21.2843 19.2843 21.5903 18.908 21.782C18.4802 22 17.9201 22 16.8 22L7.2 22C6.07989 22 5.51984 22 5.09202 21.782C4.71569 21.5903 4.40973 21.2843 4.21799 20.908C4 20.4802 4 19.9201 4 18.8V11M2 7.6L2 9.4C2 9.96005 2 10.2401 2.10899 10.454C2.20487 10.6422 2.35785 10.7951 2.54601 10.891C2.75992 11 3.03995 11 3.6 11L20.4 11C20.9601 11 21.2401 11 21.454 10.891C21.6422 10.7951 21.7951 10.6422 21.891 10.454C22 10.2401 22 9.96005 22 9.4V7.6C22 7.03995 22 6.75992 21.891 6.54601C21.7951 6.35785 21.6422 6.20487 21.454 6.10899C21.2401 6 20.9601 6 20.4 6L3.6 6C3.03995 6 2.75992 6 2.54601 6.10899C2.35785 6.20487 2.20487 6.35785 2.10899 6.54601C2 6.75992 2 7.03995 2 7.6Z"
                            stroke="#4D35AF"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </div>
                      <div>
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M18 6L6 18M6 6L18 18"
                            stroke="#DF7373"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </div>
                    </div>
                    <span>
                      {selectedBonus != bonuses.length
                        ? bonuses[selectedBonus].name
                        : "Без бонуса"}
                    </span>
                  </>
                )}
                <a
                  onClick={() => {
                    
                    if (auth) {
                    if (depositSum < 300){
                      toast.error('Минимальная сумма пополнения - 300 руб');
                      return;
                    }
                      if (senderName.length < 2 && depMethod != 'crypto') {
                        toast.error("Введите имя отправителя!");
                        return;
                      }
                      if (depMethod === "crypto") {
                        axios
                          .post(
                            process.env.REACT_APP_API + "/payment/crypto",
                            {
                              method: "crypto",
                              amount: Number(depositSum),
                            },
                            {
                              headers: {
                                Authorization:
                                  "Bearer " +
                                  localStorage.getItem("accessToken"),
                              },
                            }
                          )
                          .then((response) => {
                            if (response.data.invoice.status === "success") {
                              if (response.data.invoice.result.link != null) {
                                window.open(response.data.invoice.result.link);
                              }
                            }
                          })
                          .catch((error) => {
                            console.log(error);
                          });
                      } else {
                        var method = depMethod;
                        console.log(
                          process.env.REACT_APP_API +
                            "/payment/details?method=" +
                            method
                        );
                        axios
                          .get(
                            process.env.REACT_APP_API +
                              "/payment/details?method=" +
                              method,

                            {
                              headers: {
                                Authorization:
                                  "Bearer " +
                                  localStorage.getItem("accessToken"),
                              },
                            }
                          )
                          .then((responseFirst) => {
                            console.log({
                              sender_name: senderName,
                              amount: Number(depositSum),
                              payment_method: depMethod,
                            });
                            axios
                              .post(
                                process.env.REACT_APP_API + "/payment/bank",
                                {
                                  sender_name: senderName,
                                  amount: Number(depositSum),
                                  payment_method: depMethod,
                                },
                                {
                                  headers: {
                                    Authorization:
                                      "Bearer " +
                                      localStorage.getItem("accessToken"),
                                  },
                                }
                              )
                              .then((response) => {
                                console.log(response.data);
                                var detalis =
                                  response.data.invoice.payment_details;
                                console.log(detalis);
                                if (detalis != null) {
                                  var credentials = null;
                                  var isSbp = false;
                                  if (method === "sbp") {
                                    credentials = detalis.data;
                                    isSbp = true;
                                  } else {
                                    credentials = detalis.data;
                                  }

                                  var payDataNew = {
                                    open: true,
                                    credentials: credentials,
                                    bank: detalis.bank,
                                    amount: response.data.invoice.amount,
                                    id: response.data.transaction.id,
                                    isSbp: isSbp,
                                    recipient_name: detalis.recipient_name,
                                  };
                                  console.log(payDataNew);
                                  setPayDataTime(600);
                                  setPayData(payDataNew);
                                }
                              })
                              .catch((error) => {
                                console.log(error);
                              });
                          })
                          .catch((error) => {
                            console.log(error);
                          });
                      }
                    }
                  }}
                  className="btn"
                >
                  {t("pay")}
                </a>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
