import { useEffect, useReducer, useState } from "react";
import ProfileMenu from "../../components/ProfileMenu";
import { useTranslation } from "react-i18next";
import axios from "axios";
export default function History() {
  const [t, i18n] = useTranslation();
  const [, forceUpdate] = useReducer((x) => x + 1, 0);

  const [requestedHistoryList, setRequestedHistoryList] = useState([]);
  useEffect(() => {}, [requestedHistoryList]);
  const [gameHistory, setGameHistory] = useState([]);
  const [maxIndex, setMaxIndex] = useState(4);
  useEffect(() => {
    axios
      .get(process.env.REACT_APP_API + "/game-history/logs", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
      })
      .then((response) => {
        var result = [];
        for (var i = response.data.length - 1; i >= 0; i--) {
          result.push({ sessionId: response.data[i].sessionId });
        }

        setGameHistory(result);
      })
      .catch((error) => {});
  }, []);
  return (
    <>
      <div className="Profile-Block">
        <ProfileMenu url={"/profile/history"} />
        <div className="Profile-Block__container">
          <h1>{t("history")}</h1>
          <div className="Profile-Block__fields">
            <div className="game-history-block">
              <section>
                <p>{t("date")}</p>
                <p>{t("game")}</p>
                <p>{t("bet")}</p>
                <p>X</p>
              </section>
              <div>
                {gameHistory.length == 0 ? (
                  <button>
                    {" "}
                    <p> {t("history_is_clear")} </p>{" "}
                  </button>
                ) : (
                  gameHistory.slice(0, maxIndex).map((elem) => (
                    <>
                      <button
                        onClick={(e) => {
                          e.target.classList.toggle("open");
                          if (
                            requestedHistoryList["" + elem.sessionId] == null
                          ) {
                            axios
                              .get(
                                process.env.REACT_APP_API +
                                  "/game-history/" +
                                  elem.sessionId,
                                {
                                  headers: {
                                    Authorization:
                                      "Bearer " +
                                      localStorage.getItem("accessToken"),
                                  },
                                }
                              )
                              .then((response) => {
                                console.log(response.data);
                                if (response.data.length == 0) {
                                  var toSet = requestedHistoryList;
                                  toSet[elem.sessionId + ""] = [];
                                  setRequestedHistoryList(toSet);
                                  //forceUpdate();

                                  return;
                                }
                                var toSet = requestedHistoryList;
                                toSet[elem.sessionId + ""] = [];

                                for (
                                  var i = 0;
                                  i < response.data.length;
                                  i++
                                ) {
                                  toSet[elem.sessionId + ""].push({
                                    date: response.data[i].date,
                                    gameName:
                                      response.data[i].gameId,
                                    bet: response.data[i].bet,
                                    x:
                                      response.data[i].bet == 0
                                        ? "0.00X"
                                        : (
                                            Number(
                                              response.data[i].win
                                            ) / response.data[i].bet
                                          ).toFixed(2) + "X",
                                  });
                                }
                                console.log(toSet);

                                setRequestedHistoryList(toSet);
                                forceUpdate();
                              })
                              .catch((error) => {});
                          }
                        }}
                      >
                        {t("session")} №{elem.sessionId}{" "}
                      </button>
                      <section>
                        {requestedHistoryList[elem.sessionId + ""] == null ? (
                          "Loading..."
                        ) : requestedHistoryList[elem.sessionId + ""].length ==
                          0 ? (
                          "Games not found!"
                        ) : (
                          <>
                            {requestedHistoryList[elem.sessionId + ""].map(
                              (game) => (
                                <div>
                                  <nav>
                                    <p>{t("date")}</p>
                                    <p>{t("game")}</p>
                                    <p>{t("bet")}</p>
                                    <p>X</p>
                                  </nav>
                                  <div>
                                    <p>{game.date}</p>

                                    <p>{game.gameName}</p>
                                    <p>
                                      {game.bet.toLocaleString("en-US")} RUB
                                    </p>
                                    <p>{game.x}</p>
                                  </div>
                                </div>
                              )
                            )}
                          </>
                        )}
                      </section>
                    </>
                  ))
                )}
              </div>
              {maxIndex < gameHistory.length - 1 && (
                <a
                  onClick={() => {
                    setMaxIndex((maxIndex) => maxIndex + 4);
                  }}
                  className="btn"
                >
                  {t("load_more")}
                </a>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
