import { Link } from "react-router-dom"
import { useTranslation } from "react-i18next"
export default function Payments () {
    const [t,i18n] = useTranslation();  
    const depositTypes = [
        {method: 'visa_mir.png',type:'Банковская карта',comission: 0, time: 0, limit: 0},
        {method: 'visa_mir.png',type:'СБП',comission: 0, time: 0, limit: 0},
        {method: 'visa_mir.png',type:'Криптовалюты',comission: 0, time: 0, limit: 0},
    ]
    const withdrawTypes = [
        {method: 'visa_mir.png',type:'Visa, MasterCard',comission: 0, time: 0, limit: 0},
        {method: 'visa_mir.png',type:'Visa, MasterCard',comission: 0, time: 0, limit: 0},
        {method: 'visa_mir.png',type:'Visa, MasterCard',comission: 0, time: 0, limit: 0},
        {method: 'visa_mir.png',type:'Visa, MasterCard',comission: 0, time: 0, limit: 0},
        {method: 'visa_mir.png',type:'Visa, MasterCard',comission: 0, time: 0, limit: 0},
        {method: 'visa_mir.png',type:'Visa, MasterCard',comission: 0, time: 0, limit: 0},
        {method: 'visa_mir.png',type:'Visa, MasterCard',comission: 0, time: 0, limit: 0},
        {method: 'visa_mir.png',type:'Visa, MasterCard',comission: 0, time: 0, limit: 0},
    ]
    return ( <>
    <div className="text-page-container container payments-block">
       <div> 
     <nav>
        <h1>{t('payments')}</h1>
        
        <p>{t('payments_description')}</p>
        </nav>
<img src="/images/1.png" alt="" />
</div>
<section>
    <h2>{t('deposit_methods')}</h2> <Link to='/profile/wallet/deposit' className="btn">{t('deposit')}</Link>
</section>
<div className="deposit-types-block">
    <section>
        <p>{t('method')}</p>
        <p>{t('type')}</p>
        <p>{t('comission')}</p>
        <p>{t('process_time')}</p>
        <p>{t('transaction_limit')}</p>
    </section>
    <div>
        {depositTypes.map(elem=><>
        <div>
            <nav>
            <p>{t('method')}</p>
        <p>{t('type')}</p>
        <p>{t('comission')}</p>
        <p>{t('process_time')}</p>
        <p>{t('transaction_limit')}</p>
            </nav>
        <div>
            
            <p> <img src={'/images/payments/'+elem.method} alt="" /> </p><p>{elem.type}</p><p>{elem.comission > 0 ? elem.comission+ ' %' : t('no')} </p> <p>{elem.time > 0 ? elem.time+ ' с' : t('instantly')}</p> <p>{elem.limit > 0 ? elem.limit+ ' EUR' : t('no')}</p> </div></div></>)}
      
    </div>

</div>
<section>
    <h2>{t('withdraw_methods')}</h2> <Link to='/profile/wallet/withdraw' className="btn">{t('withdraw')}</Link>
</section>
<div className="withdraw-types-block">
    <section>
    <p>{t('method')}</p>
        <p>{t('type')}</p>
        <p>{t('comission')}</p>
        <p>{t('process_time')}</p>
        <p>{t('transaction_limit')}</p>
    </section>
    <div>
        {withdrawTypes.map(elem=><>
        <div>
            <nav>
            <p>{t('method')}</p>
        <p>{t('type')}</p>
        <p>{t('comission')}</p>
        <p>{t('process_time')}</p>
        <p>{t('transaction_limit')}</p>
            </nav>
        <div><p> <img src={'/images/payments/'+elem.method} alt="" /> </p><p>{elem.type}</p><p>{elem.comission > 0 ? elem.comission+ ' %' : t('no')} </p> <p>{elem.time > 0 ? elem.time+ ' с' : t('instantly')}</p> <p>{elem.limit > 0 ? elem.limit+ ' EUR' : t('no')}</p> </div></div></>)}
      
    </div>

</div>
    </div>
    </>)
}