import { useState } from "react"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"
export default function BonusesPage () {
    const [t] = useTranslation();
    const [salesList,setSalesList] = useState([
        {image: 'gift.png', title: '20%', desc: 'Бонус на первый депозит больше 5000 руб' },
        {image: 'gift.png', title: '20%', desc: 'Бонус на первый депозит больше 5000 руб' },
        {image: 'gift.png', title: '20%', desc: 'Бонус на первый депозит больше 5000 руб' },
        {image: 'gift.png', title: '20%', desc: 'Бонус на первый депозит больше 5000 руб' },
        {image: 'gift.png', title: '20%', desc: 'Бонус на первый депозит больше 5000 руб' },
        {image: 'gift.png', title: '20%', desc: 'Бонус на первый депозит больше 5000 руб' }
    ])
    return ( <>
    <div className="text-page-container container bonuses-block">
       <div> 
     <nav>
        <h1>{t('bonuses')}</h1>
        
        <p>{t('bonuses_desc')}</p>
        </nav>
<img src="/images/1.png" alt="" />
</div>
<section>
    <h2>{t('bonuses_list')} </h2> <Link to="/profile/wallet/deposit" className="btn">{t('deposit')}</Link>
</section>
<div className="sales-list">
{salesList.map(item=><div><img src={"/images/"+item.image} alt="" /> <h5>{item.title}</h5> <p>{item.desc}</p> </div>)}
            </div>

    </div>
    </>)
}