export default function About () {
    return ( <>
    <div className="text-page-container container">
        <h1>О нас</h1>
        
        <p>Lotos Casino предлагает широкий выбор лицензионных слотов и live игр от всемирно известных игорных брендов. Здесь вы найдете развлечения на любой вкус и любой бюджет - от бюджетных игр до серьезных ставок. Вы можете играть на доллары, евро.
        </p>

<p> Также вы можете использовать другие валюты, вы даже можете крутить слоты на криптовалюту. Пройдите простую регистрацию и получите ряд бонусов и выгодных предложений.</p>

<img src="/images/3.png" alt="" />
    </div>
    </>)
}