export default function Terms () {
    return ( <>
    <div className="text-page-container container">
        <h1>Условия и положения</h1>
        
      <p>  ПРАВИЛА И УСЛОВИЯ от 04.07.2023 </p>
<h2> 1.ВВЕДЕНИЕ</h2>
<p> 1.1. Настоящие положения и условия и документы, упомянутые ниже ("Условия"),
применяются к использованию данного веб-сайта ("Веб-сайт") и связанных с ним
или подключенных услуг (совместно именуемых "Услуги").
</p>
<p>
1.2. Вам следует внимательно изучить настоящие Условия, поскольку они содержат
важную информацию о ваших правах и обязанностях, связанных с использованием
Веб-сайта, и образуют юридически обязывающее соглашение между вами - нашим
клиентом ("Клиент") - и нами. Используя данный Веб-сайт и/или получая доступ к
услугам, вы, независимо от того, являетесь ли вы гостем или зарегистрированным
пользователем с учетной записью ("Учетная запись"), соглашаетесь соблюдать
настоящие Условия, а также любые поправки, которые могут время от времени
публиковаться. Если вы не принимаете настоящие Условия, вам следует
воздержаться от доступа к Услуге и использования Веб-сайта.
</p>
<p>
1.3. Данный сервис принадлежит и управляется компанией LetterM B.V.,
зарегистрированной и учрежденной в соответствии с законодательством Кюрасао,
с регистрационным номером 160269 и зарегистрированным адресом Dr. MJ
Hugenholtzweg 25, блок 11, Кюрасао. LetterM B.V. лицензируется и регулируется
Antillephone N.V. (лицензия № 8048/JAZ2022-040).
</p>
<h2>
2. ОБЩИЕ ПОЛОЖЕНИЯ
</h2>
<p>
2.1. Мы оставляем за собой право в любое время пересматривать и изменять
Условия (включая любые документы, упомянутые ниже и ссылки на них). Вам
следует периодически посещать эту страницу для ознакомления с Условиями.
Поправки будут обязательными и вступят в силу сразу после публикации на
данном Веб-сайте. Если вы возражаете против любых таких изменений, вы должны
немедленно прекратить использование Услуги. Ваше дальнейшее использование
Веб-сайта после такой публикации будет означать ваше согласие соблюдать
Условия с внесенными поправками. Любые споры, не урегулированные до
вступления в силу измененных Условий, будут регулироваться ранее
действовавшими Условиями.
</p>
<h2>
3. ВАШИ ОБЯЗАТЕЛЬСТВА
</h2>
<p>
Вы признаете, что в любое время при доступе на Веб-сайт и использовании Услуги:
3.1. Вы старше 18 лет или возраста, с которого разрешены азартные игры или
игорная деятельность в соответствии с законом или юрисдикцией, применимой к
вам. Мы оставляем за собой право в любое время запросить у вас документы,
подтверждающие возраст.
</p>
<p>
3.2. Вы являетесь дееспособным и можете заключить с нами юридически
обязывающее соглашение. Вы не должны заходить на Веб-сайт или пользоваться
Услугами, если вы не являетесь дееспособным.
</p>
<p>
3.3. Вы являетесь резидентом юрисдикции, в которой разрешены азартные игры.
Вы не являетесь резидентом какой-либо страны, в которой доступ к азартным
играм в Интернете для ее жителей или любого лица на территории такой страны
запрещен. Вы несете полную ответственность за то, что использование вами услуг
является законным.
</p>
<p>
3.4. Вы являетесь авторизованным пользователем используемого вами способа
оплаты.
</p>
<p>
3.5. Вы должны осуществлять все платежи в нашу пользу добросовестно и не
пытаться отменить произведенный платеж или предпринимать какие-либо
действия, которые могут привести к отмене такого платежа третьей стороной.
</p>
<p>
3.6. При размещении ставок вы можете потерять часть или все свои деньги,
внесенные на счет LOTOScasino.com в соответствии с настоящими Условиями, и
вы будете нести полную ответственность за эту потерю.
</p>
<p>
3.7. При размещении ставок вы не должны использовать любую информацию,
полученную в нарушение законодательства, действующего в стране, в которой вы
находились в момент размещения ставки.
</p>
<p>
3.8. Вы действуете не от имени другой стороны и не в коммерческих целях, а
исключительно от своего имени как частное лицо в личном качестве.</p>
<p>
3.9. Вы не должны пытаться манипулировать каким-либо рынком или элементом в
рамках Услуги недобросовестно или таким образом, который негативно влияет на
целостность Услуги или нас.
</p>
<p>
3.10. Вы должны в целом действовать добросовестно по отношению к нам в
отношении Услуги в любое время и для всех ставок, сделанных с помощью Услуги.
</p>
<p>
3.11. Вы, или, если применимо, ваши сотрудники, работодатели, агенты или члены
семьи, не зарегистрированы в качестве Партнера в нашей Партнерской программе.
</p>
<h2>
4. ОГРАНИЧЕННОЕ ИСПОЛЬЗОВАНИЕ
</h2>
<p>
4.1. Вы не должны использовать Услугу:
</p>
<p>
4.1.1. Если вам еще не исполнилось 18 лет (или вы не достигли совершеннолетия в
соответствии с законодательством применимой к вам юрисдикции) или если вы
юридически не в состоянии заключить с нами юридически обязывающее
соглашение, или вы действуете в качестве агента или иным образом от имени
лица, не достигшего 18 лет (или не достигшего совершеннолетия в соответствии с
законодательством применимой к вам юрисдикции);</p>
<p>
4.1.2. Если вы проживаете в стране, в которой доступ к азартным играм в
Интернете для ее жителей или для любого лица на территории такой страны
запрещен.
</p>
<p>
4.1.3. Если вы являетесь резидентом одной из следующих стран или осуществляете
доступ к Веб-сайту из одной из следующих стран:Соединенные Штаты Америки,
Великобритания, Испания, Франция и ее заморские территории (Гваделупа,
Мартиника, Французская Гвиана, Реюньон, Майотта, Сен-Мартен, Французская
Полинезия, Уоллис и Футуна, Новая Каледония), Нидерланды, Голландская Вест-Индия, Кюрасао, Гибралтар, Джерси.
</p>
<p>
4.1.4. Собирать никнеймы, адреса электронной почты и/или другую информацию
других Клиентов любым способом (например, путем рассылки спама, других видов
нежелательных электронных писем или несанкционированного обрамления
Сервиса или ссылки на него);
</p>
<p>
4.1.5. Нарушать или неправомерно влиять или воздействовать на деятельность
других Клиентов или работу Услуги в целом;</p>
<p>
4.1.6. Продвигать нежелательную коммерческую рекламу, партнерские ссылки и
другие формы приглашения, которые могут быть удалены из Сервиса без
уведомления;
</p>
<p>
4.1.7. Любым способом, который, по нашему обоснованному мнению, может быть
расценен как попытка обмануть Сервис или другого Клиента, использующего
Сервис или вступить в сговор с любым другим Клиентом, использующим Сервис, с
целью получения нечестного преимущества;
</p>
<p>
4.1.8. Обманным путем понизить наши шансы или нарушать любые наши права
интеллектуальной собственности, а также за любую незаконную деятельность.
4.1.9. за любую незаконную деятельность.
</p>
<p>
4.2. Вы не можете продавать или передавать свою учетную запись третьим лицам,
а также приобретать учетную запись игрока у третьих лиц.</p>
<p>
4.3. Вы не можете каким-либо образом переводить средства между счетами
игроков.
</p>
<p>
4.4. Мы можем немедленно прекратить действие вашей Учетной записи после
направления вам письменного уведомления, если вы используете Услугу в
несанкционированных целях. При определенных обстоятельствах мы также можем
предпринять против вас юридические действия.
</p>
<p>
4.5. Сотрудникам Компании, ее лицензиатам, дистрибьюторам, оптовикам,
дочерним компаниям, рекламным, промоутерским и другим агентствам, медиа-партнерам, подрядчикам, розничным торговцам и членам ближайших семей
каждого из них НЕ разрешается использовать Сервис для игры на реальные деньги
без предварительного согласия директора Компании или генерального директора.
В случае обнаружения такой деятельности, счет (счета) будет немедленно закрыт,
а все бонусы/выигрыши будут конфискованы. </p>
<h2>
5. РЕГИСТРАЦИЯ</h2>


<p> Вы соглашаетесь, что в любое время при использовании Сервиса:</p>
<p> 5.1. Мы оставляем за собой право отказать в приеме заявки на регистрацию от
любого заявителя по собственному усмотрению и без обязательства сообщать
конкретную причину.</p>

<p>
5.2. Перед использованием Услуги вы должны лично заполнить регистрационную
форму, прочитать и принять настоящие Условия. Для того чтобы начать делать
ставки в Услуге или вывести свой выигрыш, мы можем потребовать от вас стать
верифицированным Клиентом, что включает в себя прохождение определенных
проверок. От вас могут потребовать предоставить действительное удостоверение
личности и любой другой документ, который может быть сочтен необходимым. К
ним относятся, в частности, удостоверение личности с фотографией (фото
паспорта, водительских прав или национального удостоверения личности) и
недавний счет за коммунальные услуги с указанием вашего имени и адреса в
качестве доказательства проживания. Мы оставляем за собой право приостановить
отыгрыш или ограничить возможности Счета на любом Счете до получения
необходимой информации. Эта процедура осуществляется в соответствии с
действующим положением об азартных играх и законодательными требованиями
по борьбе с отмыванием денег. Кроме того, вам необходимо будет пополнить свой
Счет услуг, используя методы оплаты, указанные в разделе оплаты на нашем Веб-сайте.
</p>
<p>
5.3. Вы должны предоставить точную контактную информацию, включая
действительный адрес электронной почты ("Зарегистрированный адрес
электронной почты"), и обновлять такую информацию в будущем для сохранения
ее точности. Вы несете ответственность за обновление контактных данных на
вашей Учетной записи. Невыполнение этого требования может привести к тому,
что вы не сможете получать от нас важные уведомления и информацию связанную
с аккаунтом, включая изменения, которые мы вносим в настоящие Условия. Мы
идентифицируем и общаемся с нашими Клиентами через их Зарегистрированный
адрес электронной почты. Клиент несет ответственность за поддержание активной
и уникальной учетной записи электронной почты, за предоставление нам
правильного адреса электронной почты и за уведомление Компании о любых
изменениях в адресе электронной почты. Каждый Клиент несет полную
ответственность за обеспечение безопасности своего Зарегистрированного адреса
электронной почты для предотвращения использования Зарегистрированного
адреса электронной почты третьими лицами. Компания не несет ответственности
за любой ущерб или убытки, которые считаются или предположительно являются
результатом переписки между Компанией и Клиентом с использованием
Зарегистрированного адреса электронной почты. У любого Клиента, не имеющего
адреса электронной почты, доступного Компании, действие его Учетной записи
будет приостановлено до тех пор, пока такой адрес не будет нам предоставлен.
Мы немедленно приостановим действие вашей Учетной записи после письменного
уведомления вас об этом, если вы намеренно предоставите ложную или неточную
личную информацию. При определенных обстоятельствах мы также можем
принять против вас юридические меры за это и/или обратиться в соответствующие
органы, которые также могут принять против вас меры.
</p>
<p>
5.4. Вам разрешается зарегистрировать только одну Учетную запись в Сервисе.
Учетные записи подлежат немедленному закрытию, если будет установлено, что у
вас зарегистрировано несколько учетных записей. Это включает использование
представителей, родственников, ассоциированных, аффилированных, связанных
лиц и/или третьих лиц, действующих от вашего имени.</p>
<p>
5.5. Для обеспечения вашей финансовой состоятельности и подтверждения вашей
личности мы можем попросить вас предоставить нам дополнительную личную
информацию, например, ваше имя и фамилию, или воспользоваться услугами
сторонних поставщиков информации, которые мы сочтем необходимыми. В случае
получения дополнительной личной информации через сторонние источники мы
сообщим вам о полученных данных.
</p>
<p>
5.6. Вы должны хранить свой пароль для доступа к Услуге в тайне. При условии,
что запрашиваемая информация о счете была предоставлена правильно, мы вправе
считать, что ставки, депозиты и снятие средств были сделаны вами. Мы советуем
вам регулярно менять свой пароль и никогда не раскрывать его третьим лицам. Вы
несете ответственность за защиту своего пароля, и любая неспособность сделать
это относится исключительно к вашему риску и расходам. Вы можете выйти из
Сервиса в конце каждой сессии. Если вы считаете, что информация о вашей
Учетной записи используется третьими лицами, или ваша Учетная запись была
взломана, или ваш пароль был обнаружен третьим лицом, вы должны немедленно
уведомить нас об этом. Вы должны уведомить нас, если ваш Зарегистрированный
адрес электронной почты был взломан, однако мы можем потребовать от вас
предоставить дополнительную информацию/документацию, чтобы мы могли
проверить вашу личность. Мы немедленно приостановим действие вашей учетной
записи, как только узнаем о таком инциденте. В то же время вы несете
ответственность за все действия на вашем Счете, включая доступ третьих лиц,
независимо от того, был ли их доступ санкционирован вами.
</p>
<p>
5.7. Вы не должны ни в коем случае передавать какое-либо содержимое или
другую информацию на Сервисе другому Клиенту или любой другой стороне путем
захвата экрана (или другим подобным способом), а также отображать любую такую
информацию или содержимое во фрейме или любым другим способом, который
отличается от того, как оно отображалось бы, если бы такой Клиент или третья
сторона набрали URL-адрес Сервиса в строке браузера.
</p>
<p>
5.8. При регистрации вы получаете возможность использовать все валюты,
доступные на сайте. Некоторые платежные методы обрабатывают платежи не во
всех валютах, в таких случаях будет показана валюта обработки.
</p>
<p>
5.9. Мы не обязаны открывать для вас счет, а страница регистрации на нашем
сайте является лишь приглашением. Открытие счета для вас остается
исключительно на наше усмотрение, и если мы откажем вам в открытии счета, мы
не обязаны сообщать вам причину отказа.
</p>
<p>
5.10. После получения вашего заявления мы можем связаться с вами, чтобы
запросить дополнительную информацию и/или документацию для выполнения
наших нормативных и юридических обязательств.
</p>
<h2>
6. ВАШ АККАУНТ
</h2>
<p>
6.1. Счета могут использовать несколько валют, в этом случае все остатки по
счетам и транзакции отображаются в валюте, используемой для транзакции
</p>
<p>
6.2. Минимальный депозит 500 рублей, при использовании метода пополнения p2p.
Минимальная сумма пополнения в криптовалюте: BTC - 0,0001; ETH - 0,0001; USDT
TRC - 0,01; USDT ERC - 10; LTC - 0,1; USDC ERC-20 - 0,01; USDС TRC-20 - 10;
</p>
<p>
6.3. Мы не предоставляем кредит за использование Услуги.</p>
<p>
6.4. Мы можем закрыть или приостановить действие Учетной записи, если мы
обоснованно полагаем, что вы не соблюдаете настоящие Условия, или для
обеспечения целостности или справедливости Услуги, или если у нас есть другие
разумные основания для этого. Мы не всегда можем предоставить вам
предварительное уведомление. Если мы закроем или приостановим действие
вашего Счета из-за того, что вы не соблюдаете настоящие Условия, мы можем
отменить и/или аннулировать любые ваши ставки и удержать любые деньги на
вашем счете (включая депозит).</p>
<p>
6.5. Мы оставляем за собой право закрыть или приостановить любой Счет без
предварительного уведомления и вернуть все средства. Однако уже наступившие
договорные обязательства будут выполнены.
</p>
<p>
6.6. Если какая-либо сумма ошибочно зачислена на ваш счет, она остается нашей
собственностью, и когда нам станет известно о такой ошибке, мы уведомим вас, и
сумма будет снята с вашего счета.</p>
<p>
6.7. Вы должны сообщить нам, как только вам станет известно о каких-либо
ошибках в отношении вашего Счета.</p>
<p>
6.8. Пожалуйста, помните, что ставки делаются исключительно для развлечения и
удовольствия, и вы должны прекратить их, как только они перестанут приносить
удовольствие. Ни в коем случае не ставьте на кон то, что вы не можете позволить
себе потерять. Если вы чувствуете, что потеряли контроль над азартными играми,
мы предлагаем возможность самоисключения. Просто отправьте сообщение в наш
отдел поддержки клиентов, используя ваш зарегистрированный адрес электронной
почты, о том, что вы хотите самоисключиться, и этот запрос вступит в силу в
течение 24 часов с момента его получения. В этом случае ваш аккаунт будет
отключен до вашего дальнейшего уведомления, и вы не сможете войти в него.
</p>
<p>
6.9. Вы не можете передавать, продавать или закладывать свою Учетную запись
другому лицу. Этот запрет включает передачу любых ценных активов любого рода,
включая, но не ограничиваясь, правом собственности на счета, выигрышами,
депозитами, ставками, правами и/или требованиями в связи с этими активами,
юридическими, коммерческими или иными. Запрет на указанную передачу также
включает, но не ограничивается обременением, залогом, переуступкой,
узуфруктом, торговлей, посредничеством, залогом и/или дарением в
сотрудничестве с доверенным лицом или любой другой третьей стороной,
компанией, физическим или юридическим лицом, фондом и/или ассоциацией в
любой форме или виде.</p>
<p>
6.10. Если вы хотите закрыть свой счет у нас, пожалуйста, отправьте электронное
письмо с вашего адреса электронной почты на почту поддержки
support@lotoscas.com.</p>
<p>
6.11. Чтобы максимально упростить процесс проверки возраста, вот несколько
советов, когда вы отправляете свои документы:
Каждый документ должен быть отправлен в виде отдельного изображения
Принимаются только фотографии документов
Когда вы делаете снимок карты, убедитесь, что первые 6 и последние 4 цифры
номера вашей кредитной карты видны, а CVV скрыт
Ваша фотография должна быть в фокусе, а весь текст должен быть легко
читаемым
Ваша полная страница с фотографией паспорта или удостоверения личности
должна быть в кадре, не оставляйте никаких фрагментов и не прикрывайте их
рукой или пальцами
Ваше освещение должно быть хорошим, чтобы избежать бликов, не
используйте вспышку </p>
<p>
6.12. Зачем мне нужно верифицировать свою учетную запись? Когда вы
регистрируете новую учетную запись, мы проверяем, что вам больше 18 лет, и
подтверждаем, что вы тот, за кого себя выдаете (процесс, называемый "Знай
своего клиента" KYC). Это неотъемлемая часть ответственного отношения к
азартным играм, и закон требует, чтобы мы это делали.</p>
<p>
6.13. Что вам нужно предоставить? Чтобы подтвердить ваш возраст, мы примем
один из следующих документов:
<ul>
 

  <li>Паспорт (рекомендуется) </li>
<li>Водительские права</li> 
 <li> Национальное удостоверение личности</li>
<li> Свидетельство о рождении (проверка занимает до 24 часов)</li>
<li>Платежная карта (обратите внимание, что первые 6 и последние 4 цифры
должны быть видны, а CVV должен быть скрыт). Для подтверждения вашей
личности (KYC) нам могут потребоваться дополнительные документы, которые
также могут включать: </li>
Подписанный Кредитный договор
Счет за коммунальные услуги (менее 3 месяцев)
<li> Банковская выписка (менее 3 месяцев назад) Убедитесь, что ваши документы
находятся в приемлемом состоянии.</li>
</ul>
</p>
<p>
6.14. Как я могу отправить вам свои документы? Самый быстрый и простой способ
подтвердить свою учетную запись - онлайн. Вы также можете отправить
документы по электронной почте. Вот несколько советов о том, как сделать это
правильно с первого раза:
<ul>
    <li>
Отправка ваших документов по электронной почте</li>
<li>
Сфотографируйте каждый из ваших документов и сохраните фотографии</li>
<li> Откройте учетную запись электронной почты, которую вы использовали для
регистрации на нашем веб-сайте </li>
<li> Откройте новое электронное письмо, адресованное support@cbosscasino.com
</li>
<li> Приложите фотографии </li>
<li> Введите номер своей учетной записи или имя пользователя в строке "Тема" в
верхней части вашего электронного письма</li>
<li> Нажмите 'Отправить' </li>
</ul>
</p>
<p>
6.15. Когда вы регистрируете учетную запись на странице регистрации учетной
записи, нам потребуется, чтобы вы предоставили определенную информацию по
соображениям безопасности:
<ul>
    <li>
Ваше имя и контактные данные, включая действительный адрес электронной
почты, который должен быть уникальным для вашей учетной записи </li>
<li>
Ваша дата рождения – вам должно быть не менее 18 лет</li>
<li>
Валюта вашей ставки</li>
<li>
Страна вашего проживания</li>
</ul>
</p>
<h2>
7. НЕАКТИВНЫЙ СЧЁТ
</h2>
<p>
7.1. После 12 месяцев бездействия счет становится "неактивным", и в этот момент
мы оставляем за собой право закрыть счет. LOTOScasino.com может
переклассифицировать средства на "спящих" счетах и удалить их из поля зрения,
при условии, что с клиентом свяжутся не менее чем за 30 дней до этого и
проинформируют его о балансе и его правах в этом случае, а также о возможных
способах доступа к средствам на счете.
</p>
<h2>
8. ДЕПОНИРОВАНИЕ СРЕДСТВ
</h2>
<p>
8.1. Для внесения депозитов разрешается использовать только банковские карты
или электронные кошельки, принадлежащие лично Клиенту. ФИО держателя
банковской карты, а также личные данные в профилях любых других платежных
средств, используемых для депозитов или выплат, должны совпадать с личными
данными на аккаунте Клиента. Любые депозиты, сделанные в любой другой
валюте, будут конвертированы с использованием ежедневного обменного курса,
полученного с garantex.io, после чего Ваш Счет будет пополнен соответствующим
образом. Обратите внимание, что некоторые платежные системы могут применять
дополнительные комиссии за обмен валюты, которые будут вычтены из суммы
вашего депозита.
</p>
<p>
8.2. К депозитам и снятию средств клиентов могут применяться комиссии и сборы,
о которых можно узнать на Веб-сайте. В большинстве случаев мы берем на себя
комиссию за транзакцию при пополнении вашего счета LOTOScasino.com. Вы
несете ответственность за собственные банковские расходы, которые могут
возникнуть в связи с внесением средств на наш счет.</p>
<p>
8.3. Компания не является финансовым учреждением и использует сторонние
электронные платежные процессоры для обработки депозитов с кредитных и
дебетовых карт, они не обрабатываются непосредственно нами. Если вы вносите
средства с помощью кредитной или дебетовой карты, ваш счет будет зачислен
только в том случае, если мы получим одобрение и код авторизации от
учреждения, выпустившего платежную карту. Если эмитент вашей карты не
предоставит такого разрешения, средства на ваш счет зачислены не будут.
</p>
<p>
8.4. Вы соглашаетесь полностью оплачивать любые и все платежи и сборы,
причитающиеся нам или поставщикам платежей в связи с использованием вами
Услуги. Вы также соглашаетесь не делать никаких возвратных платежей, не
отказываться, не отменять или иным образом не отменять любые ваши депозиты, и
в любом таком случае вы вернете и компенсируете нам такие невыплаченные
депозиты, включая любые расходы, понесенные нами в процессе сбора ваших
депозитов, и вы соглашаетесь, что любые выигрыши от ставок с использованием
этих возвратных средств будут аннулированы. Вы признаете и соглашаетесь, что
ваш счет игрока не является банковским счетом и поэтому не гарантирован, не
застрахован и не защищен иным образом какой-либо системой страхования
депозитов или банковских вкладов или любой другой аналогичной системой
страхования любой другой юрисдикции, включая, но не ограничиваясь вашей
местной юрисдикцией. Кроме того, на счет игрока не начисляются проценты на
любые средства, находящиеся на нем.</p>
<p>
8.5. Если вы решили принять любое из наших рекламных или бонусных
предложений, введя бонусный код во время внесения депозита, вы соглашаетесь с
Условиями предоставления бонусов и условиями каждого конкретного бонуса.
</p>
<p>
8.6. Средства, полученные в результате преступной и/или незаконной и/или
несанкционированной деятельности, не должны размещаться у нас.</p>
<p>
8.7. Если вы вносите депозит с помощью кредитной карты, рекомендуется
сохранять копию записей о транзакциях и копию настоящих Условий.</p>
<p>
8.8. Азартные игры в Интернете могут быть незаконными в юрисдикции, в которой
вы находитесь; если это так, вы не имеете права использовать свою платежную
карту для пополнения счета на этом сайте. Вы обязаны знать законы, касающиеся
азартных игр в Интернете в стране вашего местонахождения.</p>
<h2>
9. ВЫВОД СРЕДСТВ</h2>
<p>
9.1. Вы можете снять любые неиспользованные средства, находящиеся на вашем
счете игрока, подав запрос на снятие средств в соответствии с нашими условиями
снятия средств. Минимальная сумма вывода средств за одну транзакцию
составляет 1000 рублей (или эквивалент в другой валюте), за исключением
закрытия счета, в этом случае вы можете вывести весь баланс.</p>
<p>
9.2. Комиссии за вывод средств не взимаются, если вы прокрутите (отыграете)
депозит не менее 3 раз. В противном случае мы имеем право удержать комиссию
до 30% с минимальной суммой 500 рублей (или эквивалент в валюте вашего счета)
в целях борьбы с отмыванием денег.</p>
<p>
9.3. Мы оставляем за собой право запросить удостоверение личности с
фотографией, подтверждение адреса или провести дополнительные процедуры
проверки (запросить ваше селфи, организовать звонок для проверки и т.д.) с целью
проверки личности до предоставления возможности снятия средств с вашего
счета. Мы также оставляем за собой право проводить проверку личности в любое
время в течение всего периода ваших отношений с нами.</p>
<p>
9.4. Все снятия средств должны производиться на оригинальный дебетовый,
кредитный, банковский счет, способ оплаты, использованный для внесения
платежа на ваш Счет. Мы можем, и всегда по собственному усмотрению,
разрешить вам снять средства на платежный метод, с которого не был
осуществлен ваш первоначальный депозит. При этом всегда будут проводиться
дополнительные проверки безопасности.</p>
<p>
9.5. Если вы хотите снять средства, но ваш счет недоступен, неактивен,
заблокирован или закрыт, пожалуйста, свяжитесь с нашим отделом обслуживания
клиентов через лайв-чат или по почте support@lotoscas.com.</p>
<p>
9.6. Обратите внимание, что мы не можем гарантировать успешную обработку
снятия средств или возврата денег в случае, если вы нарушите политику
ограниченного использования, указанную в пунктах 3.3 и 4.</p>
<h2>
10. ПЛАТЕЖНЫЕ ОПЕРАЦИИ И ПРОЦЕССОРЫ</h2>
<p>
10.1. Вы несете полную ответственность за выплату всех денежных средств,
причитающихся нам. Вы должны осуществлять все платежи в нашу пользу
добросовестно и не пытаться отменить произведенный платеж или предпринимать
какие-либо действия, которые приведут к отмене такого платежа третьей
стороной, чтобы избежать законно возникших обязательств. Вы будете возмещать
нам любые возвраты, отказы или отмены платежей, которые вы совершаете, и
любые убытки, понесенные нами вследствие этого. Мы оставляем за собой право
также наложить административный сбор в размере 5000 рублей или валютного
эквивалента за каждый произведенный вами возврат, отказ или отмену платежа.
</p>
<p>
10.2. Мы оставляем за собой право использовать сторонние электронные
платежные процессоры или торговые банки для обработки платежей,
производимых вами, и вы соглашаетесь быть связанными их условиями и
положениями, если они доведены до вашего сведения и эти условия не
противоречат настоящим Условиям.</p>
<p>
10.3. Все транзакции, совершенные на нашем сайте, могут быть проверены для
предотвращения отмывания денег или деятельности по финансированию
терроризма. О подозрительных транзакциях будет сообщено в соответствующий
орган.</p>
<h2>
11. ОШИБКИ
</h2>
<p>
11.1. В случае ошибки или сбоя в работе нашей системы или процессов все ставки
считаются недействительными. Вы обязаны немедленно проинформировать нас,
как только вам станет известно о любой ошибке в работе Услуги. В случае
коммуникационных или системных ошибок, ошибок или вирусов, возникающих в
связи с Услугой и/или платежей, произведенных вам в результате дефекта или
ошибки в Услуге, мы не несем ответственности перед вами или любой третьей
стороной за любые прямые или косвенные затраты, расходы, потери или
претензии, возникающие или являющиеся результатом таких ошибок, и мы
оставляем за собой право аннулировать все игры/ставки и предпринять любые
другие действия для исправления таких ошибок.</p>
<h2>
12. СООБЩЕНИЯ И УВЕДОМЛЕНИЯ</h2>
<p>
12.1. Все сообщения и уведомления, которые вы отправляете нам в соответствии с
настоящими Условиями, должны быть отправлены с помощью формы поддержки
клиентов на Веб-сайте, через лайв-чат или по почте support@lotoscas.com .
</p>
<p>
12.2. Все сообщения и уведомления, которые мы отправляем вам в соответствии с
настоящими Условиями, должны, если иное не указано в настоящих Условиях,
размещаться на Веб-сайте и/или направляться на зарегистрированный адрес
электронной почты, который хранится в нашей системе для соответствующего
Клиента. Способ такой связи определяется исключительно по нашему усмотрению.
</p>
<p>
12.3. Все сообщения и уведомления, которые должны быть предоставлены в
соответствии с настоящими Условиями вами или нами, должны быть в письменной
форме на русском или английском языке и должны быть предоставлены по
зарегистрированному адресу электронной почты в вашей учетной записи.
</p>
<p>
12.4. Время от времени мы можем связываться с вами по электронной почте с
целью предложить вам информацию об уникальных рекламных предложениях и
другую информацию от LOTOScasino.com. Вы соглашаетесь получать такие
электронные письма, когда соглашаетесь с настоящими Условиями при
регистрации на Веб-сайте. Вы можете отказаться от получения таких рекламных
предложений от нас в любое время, направив запрос в Службу поддержки
клиентов.
</p>
<h2>
13. ВОПРОСЫ, НАХОДЯЩИЕСЯ ВНЕ НАШЕГО
КОНТРОЛЯ</h2>
<p>
13.1. Мы не несем ответственности за любые сбои или задержки в предоставлении
Услуги в результате форс-мажорных обстоятельств, которые могут обоснованно
рассматриваться как находящиеся вне нашего контроля, несмотря на принятие
нами разумных предупредительных мер, таких как: стихийное бедствие, торговый
или трудовой спор, отключение электроэнергии, действие, отказ или бездействие
любого правительства или органа власти, препятствие или отказ
телекоммуникационных услуг, или любые другие задержки или сбои, вызванные
третьей стороной, и мы не несем ответственности за любые убытки или ущерб,
которые вы можете понести. В таком случае мы оставляем за собой право отменить
или приостановить предоставление Услуги, не неся при этом никакой
ответственности.
</p>
<h2>
14. ОТВЕТСТВЕННОСТЬ
</h2>
<p>
14.1. В той мере, в какой это разрешено применимым законодательством, мы не
будем компенсировать вам любые разумно предвидимые убытки или ущерб
(прямой или косвенный), которые вы можете понести в случае невыполнения нами
своих обязательств по настоящим условиям, если только мы не нарушим какие-либо обязанности, возложенные на нас законом. Мы не будем нести
ответственность перед вами, если такое невыполнение объясняется: вашей
собственной виной; третьей стороной, не связанной с выполнением нами
настоящих условий (например, проблемы, связанные с работой, подключением
коммуникационной сети или работой вашего компьютерного оборудования; или
любые другие события, которые ни мы, ни наши поставщики не могли предвидеть
или предотвратить, даже если бы мы или они проявили разумную осторожность.
Поскольку данная услуга предназначена только для потребительского
использования, мы не несем ответственности за любые деловые потери любого
рода.
</p>
<p>
14.2. Мы настоятельно рекомендуем вам позаботиться о том, чтобы проверить
пригодность и совместимость услуги с вашим собственным компьютерным
оборудованием перед использованием и принять разумные меры
предосторожности для защиты от вредоносных программ или устройств, в том
числе путем установки антивирусного программного обеспечения.</p>
<h2>
15. АЗАРТНЫЕ ИГРЫ ДЛЯ ЛИЦ, НЕ ДОСТИГШИХ
СОВЕРШЕННОЛЕТИЯ</h2>
<p>
15.1. Если мы подозреваем, что вы являетесь или получаете уведомление о том, что
вы в настоящее время моложе 18 лет или были моложе 18 лет (или не достигли
совершеннолетия в соответствии с законодательством юрисдикции, применимой к
вам), когда вы делали ставки через Услугу, ваш счет будет приостановлен
(заблокирован), чтобы предотвратить размещение вами дальнейших ставок или
снятие средств с вашего счета. Затем мы проведем расследование, в том числе
выясним, делали ли вы ставки в качестве агента или иным образом от имени лица,
не достигшего 18 лет (или не достигшего совершеннолетия в соответствии с
законодательством применимой к вам юрисдикции). Если будет установлено, что
вы: (a) в настоящее время; (b) были моложе 18 лет или моложе возраста
совершеннолетия или (c) делали ставки в качестве агента или по поручению лица
моложе 18 лет или моложе возраста совершеннолетия, все выигрыши, которые в
настоящее время или должны быть зачислены на ваш счет, будут сохранены до
достижения вами совершеннолетия или аннулированы, по нашему усмотрению; Все
выигрыши, полученные от ставок через Услугу в несовершеннолетнем возрасте,
должны быть выплачены нам по требованию. Если вы не выполните это положение,
мы будем стремиться возместить все расходы, связанные с взысканием таких сумм;
</p>
<p>
15.2. Это условие также относится к вам, если вы старше 18 лет, но размещаете
свои ставки в юрисдикции, которая устанавливает более высокий возраст, чем 18
лет, для легального размещения ставок, и вы ниже минимального возраста,
установленного законом в данной юрисдикции.</p>
<p>
15.3. В случае возникновения у нас подозрений, что вы нарушаете положения
данного пункта или пытаетесь использовать их в мошеннических целях, мы
оставляем за собой право предпринять любые действия, необходимые для
расследования данного вопроса, включая информирование соответствующих
правоохранительных органов.</p>

<h2>
16. МОШЕННИЧЕСТВО</h2>
<p>
16.1. Казино СryptoBoss придерживается строгой политики по противодействию
мошенничеству и использует ряд специализированных инструментов и практик,
направленных на выявление мошеннических действий. Если Игрок подозревается
в: </p>
<p>
16.1.1 участии в сговоре с другими игроками;</p>
<p>
16.1.2 разработке стратегий, направленных на получение выигрыша нечестным
путем;</p>
<p>
16.1.3 мошеннической деятельности в отношении других онлайн-казино или
платежных операторов;</p>
<p>
16.1.4 совершении чарджбэков (возвратных платежей) или отрицании факта
совершения каких-либо платежей;</p>
<p>
16.1.5 регистрации двух и более игровых аккаунтов;
</p>
<p>
16.1.6 игре в рулетку с минимальным риском (совершении равных ставок на черное
и красное, четное и нечетное, ставок, закрывающих 25 из 37 номеров на столе); </p>

    <p>
16.1.7 любых других попытках получить несправедливое преимущество;</p>
<p>
16.1.8 официальном банкротстве в стране проживания или других, не
ограничивающихся перечисленными выше, мошеннических действиях, то казино
LOTOS Casino оставляет за собой право закрыть аккаунт Игрока и отказать в
выплате средств. Мы имеет исключительное право на принятие соответствующего
решения и не имеет обязательств по информированию Игрока о причинах такого
решения. Казино LOTOS также оставляет за собой право передавать
информацию о мошеннических действиях, совершаемых Игроком, в
соответствующие контрольно-надзорные органы.</p>
<p>
16.2 В случае совершения Игроком чарджбэка, казино оставляет за собой право:
</p>
<p>
16.2.1 потребовать от Игрока сумму, эквивалентную сумме доступных средств на
балансе Игрока, в целях возмещения ущерба и убытков, вызванных или возникших
в результате чарджбэков;</p>
<p>
16.2.2 потребовать от Игрока дальнейшего возмещения убытков и финансового
ущерба, связавшись с ним одним из способов, указанных при регистрации
(например, по телефону, электронной почте и т.п.);</p>
<p>
16.2.3 закрыть аккаунт Игрока и/или отменить любые выигрыши, полученные в
результате такого действия или попытки выполнить такое действие.</p>
<p> 
16.3 В казино LOTOS строго запрещена адвантивная игра. Игрок подтверждает
свое согласие с тем, что при любых попытках злоупотребления приветственными
бонусами или другими промо акциями казино имеет право отменить бонусы и
конфисковать любые выигрыши, полученные с помощью таких бонусов, если: </p>
<p> 
16.3.1 использованы похищенные банковские карты;</p>
<p>
16.3.2 инициированы чарджбэки;</p>
<p>
16.3.3 создано два (или более) аккаунта с целью злоупотребления промоакциями
казино;</p>
<p>
16.3.4 предоставлены неверные регистрационные данные;</p>
<p>
16.3.5 предоставлены поддельные документы;</p>
<p>
16.3.6 совершены любые другие действия, которые могут нанести ущерб казино.</p>
<h2>
17. ИНТЕЛЛЕКТУАЛЬНАЯ СОБСТВЕННОСТЬ
</h2>
<p>
17.1. Любое несанкционированное использование нашего названия и логотипа
может привести к тому, что против вас будут предприняты юридические действия.
</p>
<p>
17.2. В отношениях между нами и вами мы являемся единственными владельцами
прав на Сервис, наши технологии, программное обеспечение и бизнес-системы
("Системы").
</p>
<p>
17.3. Вы не можете использовать наш URL, товарные знаки, торговые названия
и/или фирменный стиль, логотипы ("Знаки").</p>
<p>
17.4. За исключением случаев, прямо предусмотренных настоящими Условиями, мы
и наши лицензиары не предоставляем вам никаких явных или подразумеваемых
прав, лицензий, титулов или интересов в отношении Систем или Марки, и все такие
права, лицензии, титулы и интересы сохраняются за нами и нашими лицензиарами.
Вы соглашаетесь не использовать какие-либо автоматические или ручные
устройства для мониторинга или копирования веб-страниц или содержимого
Сервиса. Любое несанкционированное использование или воспроизведение может
привести к тому, что против вас будут предприняты юридические действия.
</p>
<h2>
18. ВАША ЛИЦЕНЗИЯ
</h2>
<p>
18.1. В соответствии с настоящими Условиями и их соблюдением вами, мы
предоставляем вам неисключительную, ограниченную, непередаваемую и не
подлежащую сублицензированию лицензию на доступ и использование Сервиса
только для ваших личных некоммерческих целей. Наша лицензия для вас
прекращается, если наше соглашение с вами в соответствии с настоящими
Условиями заканчивается.
</p>
<p>
18.2. За исключением собственного контента, вы ни при каких обстоятельствах не
имеете права изменять, публиковать, передавать, продавать, воспроизводить,
загружать, размещать, распространять, исполнять, демонстрировать, создавать
производные работы или любым другим способом использовать Сервис и/или
любой контент на нем или содержащееся в нем программное обеспечение, за
исключением случаев, когда мы прямо разрешаем это в настоящих Условиях или
иным образом на Веб-сайте. Никакая информация или контент на Сервисе или
предоставленные вам в связи с Сервисом не могут быть изменены или
модифицированы, объединены с другими данными или опубликованы в любой
форме, включая, например, скрейпинг экрана или базы данных и любую другую
деятельность, направленную на сбор, хранение, реорганизацию или
манипулирование такой информацией или контентом.
</p>
<p>
18.3. Любое несоблюдение вами данного пункта может также являться
нарушением нашей или третьей стороны интеллектуальной собственности и
других прав собственности, что может повлечь за собой гражданскую
ответственность и/или уголовное преследование.</p>
<h2>
19. ВАШЕ ПОВЕДЕНИЕ И БЕЗОПАСНОСТЬ</h2>
<p>
19.1. Для вашей защиты и защиты всех наших клиентов, размещение любого
контента на Сервисе, а также поведение в связи с ним и/или Сервисом, которое в
любом случае является незаконным, неуместным или нежелательным, строго
запрещено ("Запрещенное поведение").</p>
<p>
19.2. Если вы занимаетесь запрещенным поведением, или мы по своему
усмотрению определяем, что вы занимаетесь запрещенным поведением, ваша
Учетная запись и/или ваш доступ или использование Услуги могут быть
немедленно прекращены без вашего уведомления. В отношении вас могут быть
предприняты юридические действия со стороны другого Клиента, другой третьей
стороны, правоохранительных органов и/или нас в связи с тем, что вы участвовали
в запрещенном поведении.</p>
<p>
19.3. Запрещенное поведение включает: рекламирование и/или распространение
информации, которая, как вам известно, является ложной, вводящей в
заблуждение или незаконной; осуществление любой незаконной или
противоправной деятельности, такой как, но не ограничиваясь этим, любой
деятельностью, которая способствует или продвигает любую преступную
деятельность или предприятие, нарушает конфиденциальность другого Клиента
или любой другой третьей стороны или другие права, или которая создает или
распространяет компьютерные вирусы; причинение какого-либо вреда
несовершеннолетним; передача или создание любого контента, который является
незаконным, вредным, угрожающим, оскорбительным, неправомерным,
клеветническим, вульгарным, непристойным, развратным, жестоким, ненавистным,
расово-этническим или иным образом предосудительным; передача или создание
любого контента, который пользователь не имеет права делать доступным в
соответствии с законом, включая, без ограничений, любой контент, который
нарушает авторское право, торговую марку или другие права интеллектуальной
собственности и права собственности третьих лиц; передача любого содержания
или материала, содержащего любой программный вирус или другой компьютерный
или программный код (включая HTML), предназначенный для прерывания,
уничтожения или изменения функциональности Сервиса; вмешивание, нарушение
или перепроектирование Сервиса любым способом, включая, без ограничений,
перехват, эмуляцию или перенаправление используемых нами протоколов связи,
создание или использование читов, модификаций или хаков или любого другого
программного обеспечения, предназначенного для изменения Сервиса, или
использование любого программного обеспечения, которое перехватывает или
собирает информацию от или через Сервис; извлекать или индексировать любую
информацию из Сервиса с помощью любого робота, паука или другого
автоматизированного механизма; участвовать в любой деятельности или
действиях, которые, по нашему единоличному и абсолютно неограниченному
усмотрению, приводят или могут привести к обману или мошенничеству другого
Клиента; передавать или делать доступной любую нежелательную или
несанкционированную рекламу или массовую рассылку, такую как, но не
ограничиваясь этим, нежелательная почта, обмен мгновенными сообщениями,
"спам", письма счастья, финансовые пирамиды или другие формы вымогательства;
создавать Учетные записи на Веб-сайте автоматизированными средствами или под
ложным или мошенническим предлогом; выдавать себя за другого Клиента или
любую другую третью сторону, или любые другие действия или поступки, которые
мы обоснованно считаем противоречащими нашим принципам ведения бизнеса.
Приведенный выше список запрещенного поведения не является исчерпывающим и
может быть изменен нами в любое время или время от времени. Мы оставляем за
собой право проводить расследования и предпринимать все действия, которые мы
по своему усмотрению сочтем уместными или необходимыми в данных
обстоятельствах, включая, без ограничений, прекращение действия его Учетной
записи, а также предпринимать любые действия против любого Клиента или
третьего лица, которое прямо или косвенно участвует или сознательно позволяет
третьему лицу прямо или косвенно участвовать в Запрещенном поведении, с
уведомлением или без уведомления такого Клиента или третьего лица.
</p>
<h2>
20. ССЫЛКИ НА ДРУГИЕ САЙТЫ</h2>
<p>
Сервис может содержать ссылки на веб-сайты третьих лиц, которые не
поддерживаются нами и не связаны с нами, и над которыми мы не имеем никакого
контроля. Ссылки на такие веб-сайты предоставляются исключительно для
удобства Клиентов и никоим образом не исследуются, не контролируются и не
проверяются нами на точность или полноту. Ссылки на такие веб-сайты не
подразумевают какого-либо одобрения с нашей стороны и/или какой-либо связи со
связанными веб-сайтами, их содержанием или их владельцем (владельцами). Мы
не контролируем и не несем ответственности за их наличие, а также за их
точность, полноту, доступность и полезность. Соответственно, при посещении
таких веб-сайтов мы рекомендуем вам принять обычные меры предосторожности
при посещении нового веб-сайта, включая ознакомление с политикой
конфиденциальности и условиями использования.</p>
<h2>
21. ЖАЛОБЫ
</h2>
<p>
21.1. Если у вас есть какие-либо опасения или вопросы относительно настоящих
Условий, вы должны связаться с нашим Отделом обслуживания клиентов по почте
support@lotoscas.com и использовать свой Зарегистрированный адрес электронной 
почты во всех сообщениях с нами.</p>
    <p>
21.2. Несмотря на вышесказанное, мы не несем никакой ответственности перед
вами или любой третьей стороной при ответе на любую полученную нами жалобу
или принятии мер в связи с ней.</p> 
<p>
21.3. Если Клиент не удовлетворен тем, как была урегулирована жалоба, то Клиент
должен предоставить подробную информацию о своей претензии в наш Отдел
обслуживания клиентов. Мы приложим все разумные усилия, чтобы ответить на
запросы такого рода в течение нескольких дней (и в любом случае мы намерены
ответить на все такие запросы в течение 28 дней с момента получения).</p>
<p> 
 21.4. Споры должны быть поданы в течение трех (3) дней с момента принятия
решения по рассматриваемому вопросу. По истечении этого срока претензии не
рассматриваются. Клиент несет полную ответственность за операции по своему
счету.</p>
<p>
21.5. В случае возникновения спора между вами и нами наш отдел обслуживания
клиентов попытается достичь согласованного решения. Если наш отдел
обслуживания клиентов не сможет достичь согласованного с вами решения, вопрос
будет передан нашему руководству.</p>
<p>
21.6. Если все усилия по разрешению спора к удовлетворению Клиента не
увенчались успехом, Клиент имеет право подать жалобу в наш лицензионный
орган по почте complaints@gaminglicences.com
</p>
<h2>
22. НАРУШЕНИЕ НАСТОЯЩИХ УСЛОВИЙ
</h2>
<p>
Не ограничивая другие наши средства правовой защиты, мы можем приостановить
или прекратить действие вашей Учетной записи и отказаться от дальнейшего
предоставления вам Услуги, в любом случае без предварительного уведомления,
если, по нашему обоснованному мнению, вы нарушите любое существенное
условие настоящих Условий. Однако уведомление о любом таком действии будет
незамедлительно направлено вам.
</p>
<h2>
23. ОБЩИЕ ПОЛОЖЕНИЯ
</h2>
<p>
23.1. Срок действия соглашения. Настоящие Условия остаются в полной силе и
действии, пока вы получаете доступ или пользуетесь Услугой, являетесь Клиентом
или посетителем Веб-сайта. Настоящие Условия сохраняют силу после
прекращения действия вашей Учетной записи по любой причине.
</p>
<p>
23.2. Отказ. Никакой отказ с нашей стороны, будь то в результате поведения или
иным образом, от нарушения или угрозы нарушения вами любого условия или
положения настоящих Условий не будет иметь силы или обязательной силы для
нас, если он не сделан в письменной форме и должным образом подписан нами, и,
если иное не предусмотрено в письменном отказе, будет ограничен конкретным
нарушением, от которого отказались. Неисполнение нами в какой-либо момент
какого-либо условия настоящих Условий не должно рассматриваться как отказ от
такого положения или права на исполнение такого положения в любое другое
время.</p>
<p>
23.3. Признание. Получая в дальнейшем доступ к Услуге или используя ее, вы
подтверждаете, что прочитали, поняли и согласились с каждым пунктом
настоящих Условий. В результате этого вы настоящим безоговорочно
отказываетесь от любых будущих аргументов, претензий, требований или
разбирательств, противоречащих чему-либо, содержащемуся в настоящих
Условиях.
</p>
<p>
23.4. Язык. В случае расхождения между английской версией настоящих правил и
любой другой языковой версией, правильной будет считаться английская версия.
</p>

<p>
23.5. Регулирующее законодательство. Настоящие Условия регулируются
законодательством, действующим на территории Кюрасао.
</p>
<p>
23.6. Полное соглашение. Настоящие Условия представляют собой полное
соглашение между вами и нами в отношении вашего доступа и использования
Услуги и заменяют собой все другие предыдущие соглашения и сообщения, устные
или письменные, в отношении предмета настоящего документа.
</p>
    </div>
    </>)
}