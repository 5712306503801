import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
export default function NotFound () {
    const { t, i18n } = useTranslation();
return ( <>
    <div className="NotFoundBlock">
        <div className="container">
            
    <h1>404</h1>
    <h5>{t('page_not_found')}</h5>
    <img src="/images/4.png" alt="" />
    <Link to='/' className="btn">{t('to_main_page')}</Link>
    </div>
    </div>
    </>)
}