import { useState } from "react";
import ProfileMenu from "../../components/ProfileMenu";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
export default function Safety () {
    const [t,i18n] = useTranslation();
    const [shownQR,setShownQR] = useState(false); 

    const [auth2faConnected,setAuth2faConnected] = useState(false);
    return(<>
        <div className="Profile-Block">
            <ProfileMenu url={'/profile/safety'}/>
            <div className="Profile-Block__container">
            <div className="Mobile-Selector">
                <Link to='/profile/main' >{t('main')}</Link>
                <Link to='/profile/verification' >{t('verification')}</Link>
           
                <Link to='/profile/safety' className="selected">{t('safety')}</Link>
           
            </div>
                <span> <p>{t('profile')}</p>  <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M2.5 7.08329L4.58333 4.99996L2.5 2.91663M5.41667 7.08329L7.5 4.99996L5.41667 2.91663" stroke="white" stroke-opacity="0.3" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    <p>{t('safety')}</p>
    
    </span>
    <h1>{t('safety')}</h1>
            <div className="Profile-Block__fields">
            <div>
                <nav>
                    <p>{t('change_password')}</p>
<span>{t('old_password')}</span>
<label className="password-input">
    <input type="password" placeholder={t('old_password')} name="old-password" />
    <a onClick={(e)=>{
if (e.target.previousSibling.type == 'password')e.target.previousSibling.type = 'text';else e.target.previousSibling.type = 'password';
    }} className="password-input__eye">

  <svg width={15} height={15} viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.71434 3.1827C6.96835 3.14514 7.23038 3.125 7.50026 3.125C10.6909 3.125 12.7846 5.94053 13.4879 7.05426C13.5731 7.18906 13.6156 7.25646 13.6395 7.36042C13.6573 7.43849 13.6573 7.56166 13.6394 7.63973C13.6156 7.74369 13.5727 7.81154 13.487 7.94723C13.2996 8.24384 13.0139 8.66069 12.6353 9.11278M4.2027 4.1969C2.85141 5.11356 1.93403 6.38711 1.51319 7.0533C1.42768 7.18867 1.38492 7.25635 1.36109 7.3603C1.34319 7.43837 1.34318 7.56153 1.36107 7.6396C1.38489 7.74355 1.42746 7.81095 1.51258 7.94574C2.21596 9.05948 4.30963 11.875 7.50026 11.875C8.78677 11.875 9.89493 11.4172 10.8055 10.7979M1.87526 1.875L13.1253 13.125M6.17443 6.17417C5.83513 6.51348 5.62526 6.98223 5.62526 7.5C5.62526 8.53553 6.46473 9.375 7.50026 9.375C8.01803 9.375 8.48678 9.16513 8.82609 8.82583" stroke="#7762CD" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
  <svg width={15} height={15} viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1.51258 7.94574C1.42746 7.81096 1.3849 7.74357 1.36108 7.63963C1.34318 7.56156 1.34318 7.43844 1.36108 7.36037C1.3849 7.25643 1.42746 7.18904 1.51258 7.05426C2.21596 5.94053 4.30962 3.125 7.50025 3.125C10.6909 3.125 12.7845 5.94053 13.4879 7.05426C13.573 7.18904 13.6156 7.25643 13.6394 7.36037C13.6573 7.43844 13.6573 7.56156 13.6394 7.63963C13.6156 7.74357 13.573 7.81096 13.4879 7.94574C12.7845 9.05947 10.6909 11.875 7.50025 11.875C4.30963 11.875 2.21596 9.05947 1.51258 7.94574Z" stroke="#7762CD" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M7.50025 9.375C8.53579 9.375 9.37525 8.53553 9.37525 7.5C9.37525 6.46447 8.53579 5.625 7.50025 5.625C6.46472 5.625 5.62525 6.46447 5.62525 7.5C5.62525 8.53553 6.46472 9.375 7.50025 9.375Z" stroke="#7762CD" strokeLinecap="round" strokeLinejoin="round" />
  </svg>



    </a>
</label>
<span>{t('new_password')}</span>
<label className="password-input">
    <input type="password" placeholder={t('new_password')} name="new-password" />
    <a onClick={(e)=>{
if (e.target.previousSibling.type == 'password')e.target.previousSibling.type = 'text';else e.target.previousSibling.type = 'password';
    }} className="password-input__eye">

  <svg width={15} height={15} viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.71434 3.1827C6.96835 3.14514 7.23038 3.125 7.50026 3.125C10.6909 3.125 12.7846 5.94053 13.4879 7.05426C13.5731 7.18906 13.6156 7.25646 13.6395 7.36042C13.6573 7.43849 13.6573 7.56166 13.6394 7.63973C13.6156 7.74369 13.5727 7.81154 13.487 7.94723C13.2996 8.24384 13.0139 8.66069 12.6353 9.11278M4.2027 4.1969C2.85141 5.11356 1.93403 6.38711 1.51319 7.0533C1.42768 7.18867 1.38492 7.25635 1.36109 7.3603C1.34319 7.43837 1.34318 7.56153 1.36107 7.6396C1.38489 7.74355 1.42746 7.81095 1.51258 7.94574C2.21596 9.05948 4.30963 11.875 7.50026 11.875C8.78677 11.875 9.89493 11.4172 10.8055 10.7979M1.87526 1.875L13.1253 13.125M6.17443 6.17417C5.83513 6.51348 5.62526 6.98223 5.62526 7.5C5.62526 8.53553 6.46473 9.375 7.50026 9.375C8.01803 9.375 8.48678 9.16513 8.82609 8.82583" stroke="#7762CD" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
  <svg width={15} height={15} viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1.51258 7.94574C1.42746 7.81096 1.3849 7.74357 1.36108 7.63963C1.34318 7.56156 1.34318 7.43844 1.36108 7.36037C1.3849 7.25643 1.42746 7.18904 1.51258 7.05426C2.21596 5.94053 4.30962 3.125 7.50025 3.125C10.6909 3.125 12.7845 5.94053 13.4879 7.05426C13.573 7.18904 13.6156 7.25643 13.6394 7.36037C13.6573 7.43844 13.6573 7.56156 13.6394 7.63963C13.6156 7.74357 13.573 7.81096 13.4879 7.94574C12.7845 9.05947 10.6909 11.875 7.50025 11.875C4.30963 11.875 2.21596 9.05947 1.51258 7.94574Z" stroke="#7762CD" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M7.50025 9.375C8.53579 9.375 9.37525 8.53553 9.37525 7.5C9.37525 6.46447 8.53579 5.625 7.50025 5.625C6.46472 5.625 5.62525 6.46447 5.62525 7.5C5.62525 8.53553 6.46472 9.375 7.50025 9.375Z" stroke="#7762CD" strokeLinecap="round" strokeLinejoin="round" />
  </svg>



    </a>
</label>
<span>{t('confirm_password')}</span>
<label className="password-input">
    <input type="password" placeholder={t('confirm_password')} name="confirm-password" />
    <a onClick={(e)=>{
if (e.target.previousSibling.type == 'password')e.target.previousSibling.type = 'text';else e.target.previousSibling.type = 'password';
    }} className="password-input__eye">

  <svg width={15} height={15} viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.71434 3.1827C6.96835 3.14514 7.23038 3.125 7.50026 3.125C10.6909 3.125 12.7846 5.94053 13.4879 7.05426C13.5731 7.18906 13.6156 7.25646 13.6395 7.36042C13.6573 7.43849 13.6573 7.56166 13.6394 7.63973C13.6156 7.74369 13.5727 7.81154 13.487 7.94723C13.2996 8.24384 13.0139 8.66069 12.6353 9.11278M4.2027 4.1969C2.85141 5.11356 1.93403 6.38711 1.51319 7.0533C1.42768 7.18867 1.38492 7.25635 1.36109 7.3603C1.34319 7.43837 1.34318 7.56153 1.36107 7.6396C1.38489 7.74355 1.42746 7.81095 1.51258 7.94574C2.21596 9.05948 4.30963 11.875 7.50026 11.875C8.78677 11.875 9.89493 11.4172 10.8055 10.7979M1.87526 1.875L13.1253 13.125M6.17443 6.17417C5.83513 6.51348 5.62526 6.98223 5.62526 7.5C5.62526 8.53553 6.46473 9.375 7.50026 9.375C8.01803 9.375 8.48678 9.16513 8.82609 8.82583" stroke="#7762CD" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
  <svg width={15} height={15} viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1.51258 7.94574C1.42746 7.81096 1.3849 7.74357 1.36108 7.63963C1.34318 7.56156 1.34318 7.43844 1.36108 7.36037C1.3849 7.25643 1.42746 7.18904 1.51258 7.05426C2.21596 5.94053 4.30962 3.125 7.50025 3.125C10.6909 3.125 12.7845 5.94053 13.4879 7.05426C13.573 7.18904 13.6156 7.25643 13.6394 7.36037C13.6573 7.43844 13.6573 7.56156 13.6394 7.63963C13.6156 7.74357 13.573 7.81096 13.4879 7.94574C12.7845 9.05947 10.6909 11.875 7.50025 11.875C4.30963 11.875 2.21596 9.05947 1.51258 7.94574Z" stroke="#7762CD" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M7.50025 9.375C8.53579 9.375 9.37525 8.53553 9.37525 7.5C9.37525 6.46447 8.53579 5.625 7.50025 5.625C6.46472 5.625 5.62525 6.46447 5.62525 7.5C5.62525 8.53553 6.46472 9.375 7.50025 9.375Z" stroke="#7762CD" strokeLinecap="round" strokeLinejoin="round" />
  </svg>



    </a>
</label>
<a style={{marginTop: '4px'}} className="btn">{t('change')}</a>
                </nav>
                <nav>
                    <p>2FA {!auth2faConnected ? <span className="red_span">({t('not_connected')})</span> : <span className="green_span">({t('connected')})</span> }</p>
                <a onClick={()=>{setShownQR(true)}}  className="btn">Подключить</a>
                { shownQR ? <>
            
            <span>{t('scan_qr')}</span>
            <img src="http://qrcoder.ru/code/?12312312312312312123&10&0" className="qr-code-2FA" alt="" />
                </> : <></>}
                </nav>
            </div>
            </div>
        </div>
        </div>
        
        </>)
}