import ProfileMenu from "../../components/ProfileMenu";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import TinyFlag from "tiny-flag";
import axios from "axios";
import getUnicodeFlagIcon from "country-flag-icons/unicode";
import { hasFlag, countries } from "country-flag-icons";
import { IMaskInput } from "react-imask";
import { toast } from "react-toastify";
import { useAtom } from "jotai";
import { needFinishReg } from "../../App";
export default function Profile() {
 
  const [male, setMale] = useState(1);
  const [formData, setFormData] = useState({
    email: "",
    country: null,
    username: null,
    city: null,
    first_name: null,
    surname: null,
    address: null,
    phone: null,
    zip: null,
    bod: null,
    gender: 1,
    bonusAutoActivation: false,
    telegram_username: null
  });

  const [dataLoaded, setDataLoaded] = useState(false);
  useEffect(() => {
    console.log(countries);
    console.log(getUnicodeFlagIcon("RU"));
    if (!dataLoaded) {
      setDataLoaded(true);
      axios
        .get(process.env.REACT_APP_API + "/user/profile", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("accessToken"),
          },
        })
        .then((response) => {
     
          setFormData({
            email: response.data.email,
            country: response.data.country,
            username: response.data.username,
            city: response.data.city,
            name: response.data.name,
            surname: response.data.surname,
            address: response.data.address,
            phone: response.data.phone,
            zip: response.data.zip,
            dob: response.data.dob,
telegram_username: response.data.telegram_username,
telegram_id: response.data.telegram_id,
            gender: response.data.gender == null ? 1 : response.data.gender,
            bonusAutoActivation: response.data.bonusAutoActivation,
          });
        })
        .catch((error) => {
          // toast.error('Error while getting profile data')
        });
    }
  }, []);
  const { t, i18n } = useTranslation();
  return (
    <>
      <div className="Profile-Block">
        <ProfileMenu url={"/profile/main"} />
        <div className="Profile-Block__container">
          <div className="Mobile-Selector">
            <Link to="/profile/main" className="selected">
              {t("main")}
            </Link>
            <Link to="/profile/verification">{t("verification")}</Link>

            <Link to="/profile/safety">{t("safety")}</Link>
          </div>
          <span>
            {" "}
            <p>{t("profile")}</p>{" "}
            <svg
              width="10"
              height="10"
              viewBox="0 0 10 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M2.5 7.08329L4.58333 4.99996L2.5 2.91663M5.41667 7.08329L7.5 4.99996L5.41667 2.91663"
                stroke="white"
                stroke-opacity="0.3"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <p>{t("main")}</p>
          </span>
          <h1>{t("main")}</h1>
          <div className="Profile-Block__fields">
            <div>
              <nav>
                <p>E-mail</p>
                <input
                  type="text"
                  value={formData.email}
                  onChange={(e) => {
                    setFormData({ ...formData, email: e.target.value });
                  }}
                  placeholder={t("enter_your_email")}
                />
              </nav>
              <nav>
                <p>{t("country")}</p>
                <div className="selector">
                  <div
                    onClick={(e) => {
                      e.preventDefault();
                      e.target.parentElement.classList.toggle("open");
                    }}
                    className="selector__header"
                  >
                    <p className="selector__value">
                      {formData.country == null
                        ? t("select_your_country")
                        : formData.country}
                    </p>
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M6 9L12 15L18 9"
                        stroke="#7762CD"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>
                  <div
                    onClick={(e) => {
                      if (e.target.classList.contains("selector__body")) return;
                      e.target.parentElement.previousSibling.querySelector(
                        "p"
                      ).innerHTML = e.target.innerHTML;
                      setFormData({
                        ...formData,
                        country: e.target.textContent,
                      });
                      e.target.parentElement.parentElement.classList.remove(
                        "open"
                      );
                    }}
                    className="selector__body"
                  >
                    {countries.map((elem) => (
                      <div>
                        {new Intl.DisplayNames(["en"], { type: "region" }).of(
                          elem
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              </nav>
            </div>
            <div>
              <nav>
                <p>{t("username")}</p>
                <input
                  type="text"
                  value={formData.username}
                  onChange={(e) => {
                    setFormData({ ...formData, username: e.target.value });
                  }}
                  placeholder={t("enter_your_username")}
                />
              </nav>
           
              <nav>
                <p>{t("city")}</p>
                <input
                  type="text"
                  value={formData.city}
                  onChange={(e) => {
                    setFormData({ ...formData, city: e.target.value });
                  }}
                  placeholder={t("enter_your_city")}
                />
              </nav>
            </div>
            <div>
            <nav>
                <p>Логин Telegram</p>
                <input
                  type="text"
                  value={formData.telegram_username}
                  onChange={(e) => {
                    setFormData({ ...formData, telegram_username: e.target.value });
                  }}
                  placeholder={'Введите логин Telegram'}
                />
              </nav>
              <nav>
                <p>Telegram ID</p>
                <input
                  type="text"
                  value={formData.telegram_id}
                  onChange={(e) => {
                    setFormData({ ...formData, telegram_id: e.target.value });
                  }}
                  placeholder={'Введите Telegram ID'}
                />
              </nav>
            </div>
            <div>
              <nav>
                <p>{t("name")}</p>
                <input
                  type="text"
                  value={formData.name}
                  onChange={(e) => {
                    setFormData({ ...formData, name: e.target.value });
                  }}
                  placeholder={t("enter_your_name")}
                />
              </nav>
              <nav>
                <p>{t("address")}</p>
                <input
                  value={formData.address}
                  onChange={(e) => {
                    setFormData({ ...formData, address: e.target.value });
                  }}
                  type="text"
                  placeholder={t("enter_your_address")}
                />
              </nav>
            </div>
            <div>
              <nav>
                <p>{t("last_name")}</p>
                <input
                  value={formData.surname}
                  onChange={(e) => {
                    setFormData({ ...formData, surname: e.target.value });
                  }}
                  type="text"
                  placeholder={t("enter_your_last_name")}
                />
              </nav>
              <nav>
                <p>{t("post_index")}</p>
                <input
                  value={formData.zip}
                  onChange={(e) => {
                    setFormData({ ...formData, zip: e.target.value });
                  }}
                  type="text"
                  placeholder={t("enter_your_post_index")}
                />
              </nav>
            </div>
            <div>
              <nav>
                <p>{t("birth_date")}</p>
                <IMaskInput
                  mask={Date}
                  pattern="d{.}`m{.}`Y"
                  type="text"
                  value={formData.dob}
                  onAccept={(value, mask) => {
                    setFormData({ ...formData, dob: value });
                  }}
                  placeholder="DD.MM.YYYY"
                />
              </nav>
              <nav>
                <p>{t("mobile_phone")}</p>
                <input
                  value={formData.phone}
                  onChange={(e) => {
                    setFormData({ ...formData, phone: e.target.value });
                  }}
                  type="text"
                  placeholder="+79999999999"
                />
              </nav>
            </div>
            <div>
              <nav>
                <p>{t("gender")}</p>
                <div className="male-selector">
                  <label
                    onClick={() => {
                      setFormData({ ...formData, gender: "male" });
                    }}
                  >
                    <input
                      type="checkbox"
                      checked={formData.gender == "male"}
                      name="man"
                    />
                    <p>{t("gender_male")}</p>
                  </label>
                  <label
                    onClick={() => {
                      setFormData({ ...formData, gender: "female" });
                    }}
                  >
                    <input
                      type="checkbox"
                      checked={formData.gender == "female"}
                      name="woman"
                    />
                    <p>{t("gender_female")}</p>
                  </label>
                </div>
              </nav>
              <nav>
                <p>Автоактивация бонусов</p>
                <label className="toggle-switch">
                  <input
                    type="checkbox"
                    checked={formData.bonusAutoActivation}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        bonusAutoActivation: e.target.checked,
                      });
                    }}
                    name="bonus-auto-activation"
                  />
                  <span></span>
                </label>
              </nav>
            </div>
            <a
              onClick={() => {
                
                  
                 
                  axios
                  .patch(
                    process.env.REACT_APP_API + "/user/profile",
                    formData,
                    {
                      headers: {
                        Authorization:
                          "Bearer " + localStorage.getItem("accessToken"),
                      },
                    }
                  )
                  .then((response) => {
                    toast.success(t("data_updated"));

                  });
              }}
              className="btn"
            >
              {t("save")}
            </a>
          </div>
        </div>
      </div>
    </>
  );
}
