import React, { Suspense} from 'react';
import ReactDOM from 'react-dom/client';

import { BrowserRouter } from 'react-router-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './i18n';
import {
    FpjsProvider
  } from '@fingerprintjs/fingerprintjs-pro-react'
import ScrollToTop from './ScrollToTop';
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(

    <BrowserRouter>
      
    <ScrollToTop/>
    <Suspense fallback={<div>Loading...</div>}>
    <App />
    </Suspense>
  
    </BrowserRouter>
  
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
