export default function ResponsibleGambling () {
    return ( <>
    <div className="text-page-container container">
        <h1>Ответственная игра</h1>
        
        <p>Чувствуете необходимость заблокировать доступ к своей учетной записи?
В течение установленного периода вы не сможете войти в свой аккаунт.
Также вы будете исключены из всех рекламных предложений.</p>

    </div>
    </>)
}